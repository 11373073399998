import { CheckboxComponent } from '../../UI/CheckboxComponent/CheckboxComponent';
import { MenuItemComponent } from '../../UI/MenuItemComponent/MenuItemComponent';
import './FilterComponent.scss';
import {
	FilterState,
	handleToolTipClick,
	orderFiltersAlphabetically,
	validateScroll
} from './model';
import { Dispatch, FC, useEffect, useRef, useState, useCallback } from 'react';
import {
	FilterLevel,
	FilterType,
	FilterVM,
	PintoFilters,
	ScreenType,
	viewModeType
} from '../../../common/view-model';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/AppReducer';
import { Action } from 'redux';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { FilterTooltipComponent } from '../tooltip/FilterTooltipComponent';
import { FilterActions } from '../../../redux/FilterActions';
import { handleTracking } from '../../../helpers/handelTracking';
import { v4 as uuidv4 } from 'uuid';
import { TrackingEventStatus } from '../../../common/view-model';
import { useDispatch } from 'react-redux';
import {
	flatFirstLevelFilters,
	flatSecondLevelFilters,
	flatThirdLevelChildren,
	getFilterTypeKeyForUrl
} from '../../../helpers/urlParametersHelpers';
import { CategoryFilterComponent } from '../category-filter/CategoryFilterComponent';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
import LockOverlayComponent from '../../LockOverlay/LockOverlayComponent';
import { AppActions } from '../../../redux/AppActions';
import { UserActions } from '../../../redux/UserActions';
import { publicProductDiscoveryActions } from '../../../common/constants';
interface IMainFilterComponentProps {
	filter: PintoFilters;
	appState: AppState;
	setOpenFilterRef: (setOpen: () => void) => void;
	openIndex: number | undefined;
	parentIndex: number;
	setOpenIndex: (index: number | undefined) => void;
	setBubbleFuction?: (type: FilterType, value: () => void, filterRef: any) => void;
	clearBubbleFunctionRef?: () => void;
	currentFilterOpenIndex?: number;
	setCurrentCategory?: (category?: string) => void;
	parentFilters?: FilterVM[];
	addFilter?: (filter: FilterVM) => void;
	setLockModalContent: () => void;
	setHasClickOnFilter: (hasClicked: boolean) => void;
	registerUserClickEvent: (event: string) => void;
	clearAllFilters: () => void;
}
export const MainFilterComponent: FC<IMainFilterComponentProps> = ({
	filter,
	appState,
	setOpenFilterRef,
	openIndex,
	setOpenIndex,
	parentIndex,
	setBubbleFuction,
	clearBubbleFunctionRef,
	currentFilterOpenIndex,
	setCurrentCategory,
	parentFilters,
	addFilter,
	setLockModalContent,
	setHasClickOnFilter,
	registerUserClickEvent,
	clearAllFilters
}) => {
	const [filterComponentState] = useState({ ...FilterState, filter });
	filterComponentState.multifilterItems = [];
	const size = useWindowSize();
	const [deviceViewMode, setDeviceViewMode] = useState<viewModeType>(
		size.width > 768 ? viewModeType.DESKTOP : viewModeType.MOBILE
	);
	const { deviceScreen } = appState;
	const { selectedFilters, openFilterFunctionRef, carrotIconClicked, selectedCategory } =
		appState?.filterState!;
	const userState = appState.userState?.user;
	const filterRef = useRef<HTMLDivElement>(null);
	const filterContainerRef = useRef<HTMLDivElement>(null);
	const maxScrollHeigth = 520;
	const [isDeskTopScreen, setIsDeskTopScreen] = useState<boolean>(
		deviceScreen === ScreenType.LARGE_DESKTOP ||
			deviceScreen === ScreenType.MEDIUM_DESKTOP ||
			deviceScreen === ScreenType.SMALL_DESKTOP
	);
	const dispatch = useDispatch();
	const carrotIconClickCallback = useCallback(
		(carrotIconOpen: boolean) => {
			dispatch(FilterActions.setCarrotIconClick(carrotIconOpen));
		},
		[dispatch]
	);
	const handleClick = (index: number | undefined, filterName: string | undefined) => {
		if (isCurrentUserAnonymous() && openIndex !== index) {
			registerUserClickEvent(publicProductDiscoveryActions.CLICK_OPEN_FILTER);
		}
		setOpenIndex(openIndex === index ? undefined : index);
		if (openIndex !== index) {
			handleTracking({
				transactionId: uuidv4(),
				entityId: filter.id!,
				status: TrackingEventStatus.SUCCESS,
				type: `Click on ${filterName}`,
				timeStamp: new Date().toDateString(),
				eventPayload: {
					id: filter.id!,
					name: filter.name!,
					type: getFilterTypeKeyForUrl(filter.type!),
					url: window.location.href,
					tags: [],
					metadata: {
						user_id: userState?.id,
						user_type: userState?.user_type,
						environment: process.env.REACT_APP_ENV_NAME,
						filter_id: filter.id,
						filter_name: filter.name
					}
				}
			});
		}
	};
	const onTooltipClick = (value: string, iconId: string) => {
		handleToolTipClick(value, iconId);
		carrotIconClickCallback(true);
	};
	const handleAnonymousClick = () => {
		setHasClickOnFilter(true);
		setLockModalContent();
	};
	useEffect(() => {
		if (size.width < 768) {
			setDeviceViewMode(viewModeType.MOBILE);
		} else {
			setDeviceViewMode(viewModeType.DESKTOP);
		}
	}, [size]);
	useEffect(() => {
		if (filter.type === FilterType.CATEGORY) {
			setOpenFilterRef(() => setOpenIndex(0));
		}
	}, []);
	useEffect(() => {
		if (selectedFilters && selectedFilters.length > 0 && openFilterFunctionRef) {
			const lastFilter = selectedFilters[selectedFilters.length - 1];
			if (
				lastFilter.type === FilterType.CATEGORY ||
				lastFilter.type === FilterType.WHATS_NEW ||
				lastFilter.type === FilterType.NEW_TO_BEACON ||
				lastFilter.type === FilterType.NEW_TO_MARKET ||
				lastFilter.type === FilterType.NEXTY_FINALISTS
			) {
				openFilterFunctionRef();
			}
		}
	}, [selectedFilters, openFilterFunctionRef]);
	useEffect(() => {
		if (
			carrotIconClicked &&
			openIndex === parentIndex &&
			filterRef?.current &&
			filterRef.current.scrollHeight > maxScrollHeigth
		) {
			filterRef.current.classList.add('scrollable');
		} else {
			filterRef.current?.classList.remove('scrollable');
		}
	}, [openIndex, parentIndex, carrotIconClicked]);
	useEffect(() => {
		if (!filterContainerRef.current) return;
		setBubbleFuction &&
			setBubbleFuction(filter.type, () => setOpenIndex(parentIndex), filterContainerRef.current);
	}, [setBubbleFuction, filter, setOpenIndex, parentIndex, filterContainerRef]);
	useEffect(() => {
		return () => {
			clearBubbleFunctionRef && clearBubbleFunctionRef();
		};
	}, [clearBubbleFunctionRef]);
	useEffect(() => {
		if (deviceScreen === ScreenType.TABLET) {
			if (currentFilterOpenIndex !== undefined) {
				setOpenIndex(currentFilterOpenIndex);
			}
		} else {
			setOpenIndex(undefined);
		}
	}, [currentFilterOpenIndex, deviceScreen, setOpenIndex]);
	useEffect(() => {
		setIsDeskTopScreen(
			deviceScreen === ScreenType.LARGE_DESKTOP ||
				deviceScreen === ScreenType.MEDIUM_DESKTOP ||
				deviceScreen === ScreenType.SMALL_DESKTOP
		);
	}, [deviceScreen]);
	return (
		<div
			ref={filterContainerRef}
			data-testid='filter-container'
			className={`filter-container ${
				deviceViewMode === viewModeType.DESKTOP ? 'bottom-border' : ''
			}`}
		>
			<div className='row'>
				<div
					className={`main-filter-container ${
						filter.type === FilterType.CATEGORY && openIndex === 0 ? 'category-wrapper' : ''
					}`}
					onClick={!isDeskTopScreen ? () => handleClick(parentIndex, filter.name) : () => {}}
				>
					<span className='main-filter-name'>{filter.name}</span>
					<span
						data-testid='main-filter-name'
						className='icon-wrapper'
						onClick={() => handleClick(parentIndex, filter.name)}
					>
						{isCurrentUserAnonymous() ? (
							<i className='ri-lock-line'></i>
						) : (
							<button
								id={
									openIndex === parentIndex
										? `${filter.name?.replace(/\s+/g, '').trim().toLowerCase()}-opened`
										: `${filter.name?.replace(/\s+/g, '').trim().toLowerCase()}-closed`
								}
								type='button'
								className={`${
									openIndex === parentIndex
										? 'main-menu-close ri-subtract-line'
										: 'main-menu-open ri-add-line'
								}`}
							></button>
						)}
					</span>
				</div>
			</div>
			<div className='row'>
				{openIndex === parentIndex && (
					<div
						id={`filter-content${filter.id}`}
						ref={filterRef}
						className={`filter-content ${
							filter.type === FilterType.CATEGORY ? 'cat-margin-wrapper' : ''
						} ${
							validateScroll(`filter-content${filter.id}`, openIndex === parentIndex)
								? 'scrollable'
								: ''
						}`}
					>
						{filter?.filters?.map((item: FilterVM, index: number) => {
							return (
								<div key={index}>
									{item.children.length === 0 && item.type !== FilterType.CATEGORY ? (
										<>
											<div className='filter-tooltip-container'>
												<CheckboxComponent
													checked={item.isChecked}
													label={item.name}
													style='primary'
													parentIndex={item.id}
													index={index}
													level={FilterLevel.FIRST}
													type={item.type}
													parentLength={filter.filters.length}
													isLocked={isCurrentUserAnonymous()}
													setLockModalContent={handleAnonymousClick}
													registerUserClickEvent={registerUserClickEvent}
												/>
												{item.toolTip?.description && (
													<i
														className='ri-question-line tooltip-icon'
														id={`toltip-icon${item?.id || ''}`}
														onClick={() =>
															onTooltipClick(
																`${item.type}${item.id}`,
																`toltip-icon${item?.id || ''}`
															)
														}
													></i>
												)}
											</div>
											{item.toolTip?.description && (
												<FilterTooltipComponent
													id={`${item.type}${item.id}`}
													toolTip={item.toolTip}
												/>
											)}
										</>
									) : (
										<>
											{filter.type !== FilterType.CATEGORY && (
												<>
													{filterComponentState.multifilterItems.indexOf(filter) === -1 ? (
														<MenuItemComponent
															items={filter.filters}
															setLockModalContent={setLockModalContent}
															registerUserClickEvent={registerUserClickEvent}
														/>
													) : undefined}
													{filterComponentState.multiFilterHelper(
														filterComponentState.multifilterItems,
														filter
													)}
												</>
											)}
										</>
									)}
								</div>
							);
						})}
						{filter.type === FilterType.CATEGORY && (
							<CategoryFilterComponent
								filters={orderFiltersAlphabetically(filter.filters) || []}
								currentCategory={selectedCategory}
								setCurrentCategory={setCurrentCategory!}
								parentFilters={parentFilters}
								thirdLevelChildren={flatThirdLevelChildren(filter.filters)}
								secondLevelChildren={flatSecondLevelFilters(filter.filters)}
								addFilter={addFilter}
								selectedFilters={selectedFilters}
								userState={userState}
								setLockModalContent={setLockModalContent}
								registerUserClickEvent={registerUserClickEvent}
								clearAllFilters={clearAllFilters}
							/>
						)}
					</div>
				)}
			</div>
			{deviceViewMode === viewModeType.MOBILE && <div className='line'></div>}
		</div>
	);
};

interface IFilterComponentProps {
	appState: AppState;
	setOpenFilterRef: (setOpen: () => void) => void;
	setBubbleFuction?: (type: FilterType, value: () => void, filterRef: any) => void;
	clearBubbleFunctionRef: () => void;
	setCurrentFilterOpenIndex: (index: number) => void;
	setCurrentCategory?: (category?: string) => void;
	addFilter: (filter: FilterVM) => void;
	setLockModalContent: () => void;
	setHasClickOnFilter: (hasClicked: boolean) => void;
	registerUserClickEvent: (event: string) => void;
	clearAllFilters: () => void;
}
const FilterComponent: FC<IFilterComponentProps> = ({
	appState,
	setOpenFilterRef,
	setBubbleFuction,
	clearBubbleFunctionRef,
	setCurrentFilterOpenIndex,
	setCurrentCategory,
	addFilter,
	setLockModalContent,
	setHasClickOnFilter,
	registerUserClickEvent,
	clearAllFilters
}) => {
	const { filterState } = appState;
	const { allFilters, currentFilterOpenIndex } = filterState!;
	const { deviceScreen } = appState;
	const [openIndex, setOpenIndex] = useState<number | undefined>();
	useEffect(() => {
		if (deviceScreen === ScreenType.TABLET && openIndex !== undefined) {
			setCurrentFilterOpenIndex(openIndex);
		}
	}, [openIndex, deviceScreen, setCurrentFilterOpenIndex]);

	return (
		<div className='filters-container'>
			{allFilters && allFilters.length > 0 && (
				<>
					{allFilters.map((filter, index) => (
						<MainFilterComponent
							key={index}
							filter={filter}
							appState={appState}
							setOpenFilterRef={setOpenFilterRef}
							parentIndex={index}
							setOpenIndex={setOpenIndex}
							openIndex={openIndex}
							setBubbleFuction={setBubbleFuction}
							clearBubbleFunctionRef={clearBubbleFunctionRef}
							currentFilterOpenIndex={currentFilterOpenIndex}
							setCurrentCategory={setCurrentCategory}
							parentFilters={
								appState.filterState?.allFilters?.filter(f => f.type === FilterType.CATEGORY)[0]
									?.filters || []
							}
							addFilter={addFilter}
							setLockModalContent={setLockModalContent}
							setHasClickOnFilter={setHasClickOnFilter}
							registerUserClickEvent={registerUserClickEvent}
							clearAllFilters={clearAllFilters}
						/>
					))}
				</>
			)}
		</div>
	);
};
const mapStateToProps = (state: AppState) => ({
	...state
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setOpenFilterRef: (setOpen: () => void) => {
		return dispatch(FilterActions.addOpenFilterFunctionRef(setOpen));
	},
	setBubbleFuction: (type: FilterType, value: () => void, filterRef: any) => {
		return dispatch(FilterActions.setBubbleFunctionRef({ type, value, filterRef }));
	},
	clearBubbleFunctionRef: () => {
		return dispatch(FilterActions.clearBubbleFunctionRef());
	},
	setCurrentFilterOpenIndex: (index: number) => {
		return dispatch(FilterActions.setCurrentFilterOpenIndex(index));
	},
	setCurrentCategory: (category?: string) => {
		return dispatch(FilterActions.setCategory(category));
	},
	addFilter: (filter: FilterVM) => {
		return dispatch(FilterActions.addFilter(filter));
	},
	setLockModalContent: () => {
		dispatch(
			AppActions.setModalContent({
				content: <LockOverlayComponent />,
				showCloseIcon: true,
				canScroll: false,
				closeOnDocumentClick: true
			})
		);
	},
	setHasClickOnFilter: (hasClicked: boolean) => {
		dispatch(UserActions.clickOnFilter(hasClicked));
	},
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	},
	clearAllFilters: () => {
		dispatch(FilterActions.clearAllFilters());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent);
