import { useState } from 'react';
import './MenuProfileComponent.scss';
import { DEFAULT_USER_MENU, USER_ADMIN_MENU, IMenuProps, IMenuItem } from './model';

export const MenuProfileComponent: React.FC<IMenuProps> = ({
	menuItems = process.env.REACT_APP_USE_DEFAULT_MENU === 'true'
		? DEFAULT_USER_MENU
		: USER_ADMIN_MENU,
	onSelectedItem,
	selectedItem
}) => {
	const [openSubMenu, setOpenSubMenu] = useState<boolean>(false);

	const toggleSubMenu = () => {
		setOpenSubMenu(!openSubMenu);
	};
	const handleItemMenu = (key: any) => {
		onSelectedItem(key);
		if (key !== 4) {
			setOpenSubMenu(false);
		}
	};
	return (
		<ul className='menu'>
			{Object.entries(menuItems).map(([key, value]) => {
				const isActive = selectedItem === key;
				const itemClass = isActive ? 'active' : '';
				const isOpen = openSubMenu || Number(selectedItem) > 4;
				if (value.options) {
					return (
						<div
							key={key}
							className={`tab-container title-submenu ${itemClass} ${isOpen ? 'active' : ''}`}
							onClick={() => {
								toggleSubMenu();
							}}
						>
							<li key={key} className={`tab-text `}>
								{value.title}
								<i className={isOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'} />
								<div className={`submenu ${isOpen ? 'show' : ''}`}>
									<ul className='menu'>
										{isOpen &&
											Object.entries(value.options).map(([key, value]: any) => {
												const subMenuItemActive = selectedItem === key;
												const itemClassSub = subMenuItemActive ? 'active-sub' : '';
												return (
													<div
														key={key}
														className={`tab-container ${itemClassSub}`}
														onClick={() => handleItemMenu(key as keyof IMenuItem)}
													>
														<li key={key} className={`tab-text`}>
															{value.title}
														</li>
													</div>
												);
											})}
									</ul>
								</div>
							</li>
						</div>
					);
				}
				return (
					<div
						key={key}
						className={`tab-container ${itemClass}`}
						onClick={() => handleItemMenu(key as keyof IMenuItem)}
					>
						<li key={key} className={`tab-text`}>
							{value.title}
						</li>
					</div>
				);
			})}
		</ul>
	);
};
