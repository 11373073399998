import { newHopeAudienceEmail } from '../../../common/constants';

const DEFAULT_STATE: IBrandOnboardFooterState = {
	viewText: 'View',
	onboardingGuideText: 'our onboarding guide',
	orText: 'or',
	videoTutorialText: 'video tutorials.',
	learnMoreAboutText: 'Learn more about',
	newHopeStandardsText: 'New Hope Network’s Standards',
	reachOutText: 'Reach out to our Audience Success Team at',
	audienceText: newHopeAudienceEmail,
	onboardingConcernText: 'with any onboarding questions or concerns.',
	onboardingGuideLink: 'https://s43475.pcdn.co/wp-content/uploads/2023/08/OnboardingAugUpdate.pdf',
	videoTutorialLink: 'https://standards.newhope.com/onboarding/?playlist=0e0863e&video=49ea44c',
	newHopeNetworkLink: 'https://standards.newhope.com/',
	audienceLink: newHopeAudienceEmail,
	footerTitle: 'We’re Here To Help!'
};

interface IBrandOnboardFooterState {
	viewText: string;
	onboardingGuideText: string;
	orText: string;
	videoTutorialText: string;
	learnMoreAboutText: string;
	newHopeStandardsText: string;
	reachOutText: string;
	audienceText: string;
	onboardingConcernText: string;
	footerTitle: string;
	onboardingGuideLink: string;
	videoTutorialLink: string;
	newHopeNetworkLink: string;
	audienceLink: string;
}

export const BrandOnboardFooterModel = {
	...DEFAULT_STATE
};
