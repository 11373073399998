import React from 'react';
import { BrandInfo } from '../../../../redux/AppReducer';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import './StandardsApplicationComponent.scss';
import { useEffect, useState } from 'react';
interface IStandardsApplication {
	brandState: any;
}
const StandardsApplicationComponent: React.FC<IStandardsApplication> = ({ brandState }) => {
	const [isVisible, setVisibility] = useState<boolean>(true);
	const [state, setState] = useState<BrandInfo>();
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (brandState) {
			setState(brandState);
		}
	}, [brandState]);
	return (
		<>
			<TitleForms
				isVisible={isVisible}
				titleText='Standards Application Information'
				toggleVisibility={toggleVisibility}
				readOnly
			></TitleForms>
			{isVisible && (
				<>
					<ContainerForms
						FormComponent1={
							<div>
								<LabelForms text='Name'></LabelForms> <p>{state?.contact_name}</p>
							</div>
						}
						FormComponent2={
							<div>
								<LabelForms text='Primary Email'></LabelForms>
								<p>{state?.contact_email}</p>
							</div>
						}
					></ContainerForms>
				</>
			)}
		</>
	);
};

export default React.memo(StandardsApplicationComponent);
