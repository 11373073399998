const DEFAULT_STATE: IHomePageOnboardModel = {
	title: 'Want Your Products on Beacon Discovery?',
	metrics: [
		{
			value: '1,200+',
			name: 'Brands'
		},
		{
			value: '15,000+',
			name: 'Products Favorited'
		},
		{
			value: '500+',
			name: 'Retailers Use Beacon'
		},
		{
			value: '135K+',
			name: 'Page Views'
		}
	],
	buttonTitle: 'Get Started'
};

interface IHomePageOnboardModel {
	title: string;
	metrics: { name: string; value: string }[];
	buttonTitle: string;
}
export const HomePageOnboardModel = {
	...DEFAULT_STATE
};
