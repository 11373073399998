import { useSelector } from 'react-redux';
import './TitleForms.scss';
import { RootState } from '../../../../redux/AppReducer';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useEffect, useState } from 'react';
import { ScreenType } from '../../../../common/view-model';
import React from 'react';
interface ITitleForm {
	isEditing?: boolean;
	isVisible: boolean;
	onUpdate?: () => void;
	toggleEditing?: () => void;
	toggleVisibility: () => void;
	titleText: string;
	readOnly?: boolean;
	warning?: boolean;
	isValid?: boolean;
}

const TitleForms: React.FC<ITitleForm> = ({
	isEditing,
	isVisible,
	toggleEditing,
	toggleVisibility,
	onUpdate,
	titleText,
	readOnly,
	warning,
	isValid
}) => {
	const [breakTitle, setBreakTitle] = useState<boolean>(false);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const windowsSize = useWindowSize().width;
	useEffect(() => {
		if (
			(deviceScreen === ScreenType.MOBILE && titleText.length > 18) ||
			(deviceScreen === ScreenType.MOBILE && titleText.length > 9 && readOnly)
		) {
			setBreakTitle(true);
		}
	}, [deviceScreen, windowsSize, titleText, readOnly]);
	if (readOnly && warning) {
		return (
			<>
				{' '}
				<div className={`form-title-container read-only warning ${breakTitle ? 'break' : ''}`}>
					<span className='form-title read-only'>
						{titleText}
						<p>
							<i className='ri-question-line' /> Action Required
						</p>
					</span>

					<div className='read-only-edit read-only'>
						<p className=''>This section cannot be edited</p>
						<i
							className={isVisible ? `ri-arrow-up-s-line` : 'ri-arrow-down-s-line'}
							onClick={toggleVisibility}
						/>
					</div>
				</div>
			</>
		);
	}
	if (readOnly) {
		return (
			<>
				{' '}
				<div className={`form-title-container read-only ${breakTitle ? 'break' : ''}`}>
					<h3 className='form-title read-only'>{titleText}</h3>
					<div className='read-only-edit read-only'>
						<p className=''>This section cannot be edited</p>
						<i
							className={isVisible ? `ri-arrow-up-s-line` : 'ri-arrow-down-s-line'}
							onClick={toggleVisibility}
						/>
					</div>
				</div>
			</>
		);
	}

	return (
		<div className={`form-title-container ${breakTitle ? 'break' : ''}`}>
			<h3 className='form-title'>{titleText}</h3>
			<div className='form-edit'>
				{isEditing ? (
					<div className='edit-buttons save' onClick={isValid ? onUpdate : () => {}}>
						<i className='ri-save-line' />
						<p className='save-button'>Save Changes</p>
					</div>
				) : (
					<div className='edit-buttons edit' onClick={toggleEditing}>
						<i className='ri-edit-line' />
						<p>Edit Details</p>
					</div>
				)}
				<i
					className={isVisible ? `ri-arrow-up-s-line` : 'ri-arrow-down-s-line'}
					onClick={toggleVisibility}
				/>
			</div>
		</div>
	);
};

export default React.memo(TitleForms);
