import { ChangeEvent } from 'react';
import './TextInputComponent.scss';
type IValueInput = string | number;
interface ITextInput {
	defaultValue?: string;
	disabled?: boolean;
	error?: boolean;
	helperText?: React.ReactElement;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	placeholder: string;
	required?: boolean;
	iconStart?: any;
	iconEnd?: any;
	link?: string;
	errorLink?: boolean;
	textStart?: string;
	textEnd?: string;
	name: string;
	value: IValueInput;
	type?: 'text' | 'number' | 'email' | 'password';
	errorIcon?: any;
	errorHelperText?: React.ReactElement;
}

const TextInputComponent: React.FC<ITextInput> = ({
	defaultValue,
	disabled,
	error,
	helperText,
	placeholder,
	required,
	iconStart,
	iconEnd,
	link,
	name,
	onChange,
	textEnd = '',
	textStart = '',
	value,
	type = 'text',
	errorIcon,
	errorHelperText
}) => {
	const showStart = iconStart || textStart.length > 0;
	const showEnd = iconEnd || textEnd.length > 0;

	return (
		<div className={`${showStart ? 'with-start-text' : ' '} text-input-container`}>
			<div className={`${error ? 'error' : disabled ? 'disabled' : 'default'} main-container`}>
				<div
					className={`${disabled ? 'disabled' : ''} ${
						error ? 'error' : 'success'
					} input-form-container`}
				>
					{showStart && (
						<div className='label-icon-start'>
							<div className='text-start'>{textStart}</div>
							{iconStart}
						</div>
					)}
					<input
						type={type}
						className='input-form'
						disabled={disabled}
						name={name}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
					></input>

					{error ? <i className='ri-error-warning-fill' /> : null}
					{showEnd && (
						<div className='label-icon-end'>
							<div>{textEnd}</div>
							{iconEnd}
						</div>
					)}
				</div>
			</div>
			{helperText ? <div className='helper-text'>{helperText}</div> : null}
			{error ? (
				<>
					<div className='error-message'>
						<div>{errorHelperText}</div>
					</div>
				</>
			) : null}
		</div>
	);
};

export default TextInputComponent;
