import { ofType, StateObservable } from 'redux-observable';
import { catchError, from, mergeMap, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { Observable } from 'rxjs/internal/Observable';
import { handleTracking } from '../helpers/handelTracking';
import { UserActions } from './UserActions';
import { toast } from 'react-toastify';
import { IToastState, sortOptionType } from '../common/view-model';
import { AppActions } from './AppActions';
import {
	getIirisCookie,
	getLocalStorageValue,
	IirisIdentityKey,
	removeAllAuthenticationKeysFromLocalStorage,
	setLocalStorageValue
} from '../helpers/authenticationHelper';
import { convertUserLocalStorageKey, lastUrlLocalStorageKey } from '../common/constants';
import { GET_PROFILE } from '../graphql/queries/profile';
import {
	ADD_FAVORITE,
	CREATE_PROFILE,
	DELETE_FAVORITE,
	UPDATE_PROFILE
} from '../graphql/mutations/profile';
import { apolloClient } from '../graphql/ApolloClient';
import { GET_BRAND } from '../graphql/queries/brand';
import { GET_PRODUCTS } from '../graphql/queries/products';
export class UserEpics {
	static init() {
		return [
			UserEpics.getUserProfileEpic,
			UserEpics.getUserProfileBeaconEpic,
			UserEpics.getBrandInfo,
			UserEpics.saveUserIngredientsTemplateIdEpic,
			UserEpics.updateUserFavoriteProductsEpic,
			UserEpics.updateUserCompanyDetailsEpic,
			UserEpics.downloadFavoriteProductsEpic,
			UserEpics.createUserProfileBeacon,
			UserEpics.setCreateUserProfileErrorEpic,
			UserEpics.getAnonymousUserTokenEpic,
			UserEpics.registerUserClickEventEpic,
			UserEpics.updateUserUnsubscribe,
			UserEpics.updateUserSubscribe,
			UserEpics.getProductsInfo,
			UserEpics.getUserProfileBeaconLogin
		];
	}

	static updateUserSubscribe = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.UPDATE_USER_SUBSCRIBE),
			mergeMap(_action => {
				const { payload } = _action;

				const request = {
					method: 'PUT',
					url: `${process.env.REACT_APP_BEACON_API_URL}/profile/subscribe-newsletter/${payload.email}`,
					headers: {
						'Content-Type': 'application/json',
						Authorization: getIirisCookie()
					},
					crossDomain: true
				};

				return ajax(request).pipe(
					mergeMap((result): any => {
						const { response }: any = result;
						return from([
							UserActions.updateUserSubscribeSuccess({
								title: 'Success!',
								message: 'You have been successfully subscribed to product alert emails.',
								type: 'success',
								position: toast.POSITION.TOP_RIGHT,
								closeOnClick: false,
								autoClose: 5000
							}),
							UserActions.updateSubscribeStatus(true)
						]);
					}),
					catchError(error =>
						of(
							UserActions.updateUserSubscribeError({
								title: 'Changes not Saved',
								message: 'Your account settings could not be updated, please try again.',
								type: 'error',
								position: toast.POSITION.TOP_RIGHT,
								closeOnClick: false,
								autoClose: 5000
							})
						)
					)
				);
			})
		);
	};

	static updateUserUnsubscribe = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.UPDATE_USER_UNSUBSCRIBE),
			mergeMap(_action => {
				const { payload } = _action;

				const request = {
					method: 'PUT',
					url: `${process.env.REACT_APP_BEACON_API_URL}/profile/unsubscribe-newsletter/${payload.email}`,
					headers: {
						'Content-Type': 'application/json',
						Authorization: getIirisCookie()
					},
					crossDomain: true,
					body: {
						reason: payload.reason
					}
				};

				return ajax(request).pipe(
					mergeMap((result): any => {
						const { response }: any = result;
						return of(
							UserActions.updateUserProfileEpic({
								title: 'Feedback Sent',
								message: 'Thank you for letting us know! We appreciate your input.',
								type: 'success',
								position: toast.POSITION.TOP_RIGHT,
								closeOnClick: false,
								autoClose: 5000
							})
						);
					}),
					catchError(error =>
						of(
							UserActions.userProfileError({
								title: 'Changes not Saved',
								message: 'Your account settings could not be updated, please try again.',
								type: 'error',
								position: toast.POSITION.TOP_RIGHT,
								closeOnClick: false,
								autoClose: 5000
							})
						)
					)
				);
			})
		);
	};

	static getUserProfileEpic = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.GET_USER_PROFILE),
			mergeMap(_action => {
				const userEmail = getLocalStorageValue(IirisIdentityKey.USER_EMAIL);
				const request = {
					method: 'POST',
					url: `${process.env.REACT_APP_IRIS_PROFILE_END_POINT}`,
					headers: {
						'Content-Type': 'application/json',
						Authorization: getIirisCookie()
					},
					crossDomain: true,
					body: {
						email: userEmail,
						profile_type: 'business_email'
					}
				};
				return ajax(request).pipe(
					mergeMap((result): any => {
						const { response }: any = result;
						const userProfile = response?.status === 'success' ? response?.data?.[0] : null;
						if (response?.status === 'success') {
							return from([
								UserActions.getUserProfileSuccess(userProfile),
								{ type: UserActions.GET_USER_PROFILE_BEACON }
							]);
						} else {
							return of({ type: AppActions.UNAUTHORIZED_ERROR });
						}
					}),
					catchError(() => of({ type: AppActions.UNAUTHORIZED_ERROR }))
				);
			})
		);
	};

	static getUserProfileBeaconLogin = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.GET_USER_PROFILE_BEACON_LOGIN),
			mergeMap(_action => {
				const userId = getLocalStorageValue(IirisIdentityKey.USER_ID);
				const userEmail = getLocalStorageValue(IirisIdentityKey.USER_EMAIL);
				if (userEmail && getIirisCookie()) {
					return from(
						apolloClient.query({
							query: GET_PROFILE,
							variables: { id: userId }
						})
					).pipe(
						mergeMap(response => {
							if (response.data) {
								return of(UserActions.updaUserBeaconInfoStatus(true));
							} else {
								return from([
									{ type: AppActions.UNAUTHORIZED_ERROR },
									UserActions.updaUserBeaconInfoStatus(false)
								]);
							}
						}),
						catchError(error => {
							console.error(error);
							return from([
								{ type: UserActions.GET_USER_PROFILE_ERROR },
								UserActions.updaUserBeaconInfoStatus(false)
							]);
						})
					);
				} else {
					return of({ type: AppActions.UNAUTHORIZED_ERROR });
				}
			})
		);
	};

	static getUserProfileBeaconEpic = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.GET_USER_PROFILE_BEACON),
			mergeMap(_action => {
				const userId = getLocalStorageValue(IirisIdentityKey.USER_ID);
				const userEmail = getLocalStorageValue(IirisIdentityKey.USER_EMAIL);
				if (userEmail && getIirisCookie()) {
					return from(
						apolloClient.query({
							query: GET_PROFILE,
							variables: { id: userId }
						})
					).pipe(
						mergeMap(response => {
							if (response.data) {
								return of(
									UserActions.getUserProfileBeaconSuccess({
										companyDetails: response.data.getProfile.companyDetails,
										ingredientsTemplateId: response.data.getProfile.ingredientsTemplateId,
										favoriteProducts: response.data.getProfile.favoriteProducts
									})
								);
							} else {
								return of({ type: AppActions.UNAUTHORIZED_ERROR });
							}
						}),
						catchError(error => {
							return of({ type: UserActions.GET_USER_PROFILE_ERROR });
						})
					);
				} else {
					return of({ type: AppActions.UNAUTHORIZED_ERROR });
				}
			})
		);
	};
	static getBrandInfo = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.GET_USER_PROFILE_BEACON_SUCCESS),
			mergeMap(_action => {
				const brandId = '1686755932_6489da5c3cec32.83046379';
				return from(
					apolloClient.mutate({
						mutation: GET_BRAND,
						variables: {
							id: brandId
						}
					})
				).pipe(
					mergeMap(response => {
						if (response.data) {
							return of(UserActions.getUserBrandProfileSuccess(response.data.brand));
						} else {
							return of();
						}
					})
				);
			})
		);
	};

	static getProductsInfo = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.GET_USER_PROFILE_BEACON_SUCCESS),
			mergeMap(_action => {
				const { sortBySelected, sortByOptions } = state$.value.appState?.filterState;
				const { ingredientsTemplateId } = state$.value.appState?.userState;
				const currentEvent = state$?.value?.appState?.storeState?.getCurrentEvent;
				const sortBy =
					sortBySelected?.sortType === sortOptionType.MOST_RELEVANT
						? sortByOptions?.find(
								(x: { sortType: sortOptionType }) => x.sortType === sortOptionType.ALPHABETICAL
						  )
						: sortBySelected;
				const brandId = '1686755932_6489da5c3cec32.83046379';
				return from(
					apolloClient.query({
						query: GET_PRODUCTS,
						variables: {
							genericFilters: [
								{
									key: 'Brand',
									ids: [brandId]
								}
							],
							sortAttribute: sortBy?.productSort.attribute,
							sortDirection: sortBy?.productSort.order,
							templateId: String(ingredientsTemplateId),
							expoEventId: currentEvent?.id
						}
					})
				).pipe(
					mergeMap(response => {
						if (response.data) {
							return of(UserActions.getUserProductsProfileSuccess(response.data.products.products));
						} else {
							return of();
						}
					})
				);
			})
		);
	};

	static saveUserIngredientsTemplateIdEpic = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.SAVE_USER_INGREDIENTS_TEMPLATE_ID),
			mergeMap(_action => {
				const { payload } = _action;
				const profileSaveIngredientMessage = 'Your Ingredient template has been updated';
				const profileSaveIngredientTitle = 'Changes Saved!';
				const onboardingSaveIngredientMessage =
					'Your ingredients template was saved and only matching products will be shown.';
				const onboardingSaveIngredientTitle = 'Template Saved!';
				const isOnProfilePage = window.location.pathname.includes('profile');
				return from(
					apolloClient.mutate({
						mutation: UPDATE_PROFILE,
						variables: {
							id: getLocalStorageValue(IirisIdentityKey.USER_ID),
							ingredientsTemplateId: payload
								? payload.toString()
								: state$?.value?.appState?.userState?.ingredientsTemplateId.toString(),
							email: getLocalStorageValue(IirisIdentityKey.USER_EMAIL)
						}
					})
				).pipe(
					mergeMap(response => {
						return of(
							UserActions.saveIngredientTemplateSuccess({
								message: isOnProfilePage
									? profileSaveIngredientMessage
									: onboardingSaveIngredientMessage,
								title: isOnProfilePage ? profileSaveIngredientTitle : onboardingSaveIngredientTitle,
								type: 'success',
								position: toast.POSITION.TOP_RIGHT,
								closeOnClick: false,
								autoClose: 5000,
								ingredientsTemplateId: response.data.updateProfile.ingredientsTemplateId
							})
						);
					}),
					catchError(() =>
						of(
							UserActions.saveIngredientTemplateError({
								message: 'Your ingredients template could not be updated, please try again.',
								type: 'error',
								title: 'Changes not Saved',
								position: toast.POSITION.TOP_RIGHT,
								closeOnClick: false,
								autoClose: 5000
							})
						)
					)
				);
			})
		);
	};

	static updateUserCompanyDetailsEpic = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.UPDATE_USER_PROFILE),
			mergeMap(_action => {
				const { companyDetails, trackingPayload, userProfile } = _action.payload;
				const { isEditing, ...rest } = companyDetails;
				const email = getLocalStorageValue(IirisIdentityKey.USER_EMAIL);
				const ingredientsTemplateId = isEditing
					? state$?.value?.appState?.userState?.ingredientsTemplateId.toString()
					: '1';
				const isOnProfilePage = window.location.pathname.includes('profile');
				return from(
					apolloClient.mutate({
						mutation: UPDATE_PROFILE,
						variables: {
							id: getLocalStorageValue(IirisIdentityKey.USER_ID),
							ingredientsTemplateId,
							email,
							companyDetails: rest
						}
					})
				).pipe(
					mergeMap(response => {
						if (isOnProfilePage) {
							const updateIrisUserProfile = new Promise((resolve, reject) => {
								window.IIRISProfileMgr.setIIRISProfile(userProfile).then(({ data }: any) => {
									resolve(data[0]);
								});
							});
							const updateIrisUserProfile$ = from(updateIrisUserProfile);

							return from(
								updateIrisUserProfile$.pipe(
									mergeMap((data: any) => {
										return of(
											UserActions.updateUserProfileEpic({
												title: 'Changes Saved!',
												message: 'Your account settings have been updated.',
												type: 'success',
												position: toast.POSITION.TOP_RIGHT,
												closeOnClick: false,
												autoClose: 5000,
												companyDetails: response.data.updateProfile.companyDetails,
												ingredientsTemplateId: response.data.updateProfile.ingredientsTemplateId,
												favoriteProducts: response.data.updateProfile.favoriteProducts,
												userProfile: data
											})
										);
									})
								)
							);
						} else {
							trackingPayload && handleTracking(trackingPayload);
							return of(
								UserActions.updateCompanyDetailsSuccess({
									companyDetails: response.data.updateProfile.companyDetails
								})
							);
						}
					}),
					catchError(error =>
						of(
							UserActions.userProfileError({
								title: 'Changes not Saved',
								message: 'Your account settings could not be updated, please try again.',
								type: 'error',
								position: toast.POSITION.TOP_RIGHT,
								closeOnClick: false,
								autoClose: 5000
							})
						)
					)
				);
			})
		);
	};

	static updateUserFavoriteProductsEpic = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.UPDATE_USER_FAVORITE_PRODUCTS),
			mergeMap(action => {
				const currentFavorites = state$?.value?.appState?.userState?.favoriteProducts;
				const currentFavoritesIds = currentFavorites?.map((favorite: any) => favorite?.pid);
				const { trackingEvent, productId } = action.payload;
				const isAdding = !currentFavoritesIds?.includes(productId);
				const errorMessageOnUpdateFavorites: IToastState = {
					title: 'Changes not Saved',
					message: 'Your favorites could not be updated, please try again.',
					type: 'error',
					position: toast.POSITION.TOP_RIGHT,
					closeOnClick: false,
					autoClose: 5000
				};
				return from(
					apolloClient.mutate({
						mutation: isAdding ? ADD_FAVORITE : DELETE_FAVORITE,
						variables: {
							id: getLocalStorageValue(IirisIdentityKey.USER_ID),
							productId: productId
						}
					})
				).pipe(
					mergeMap(response => {
						if (response && trackingEvent && isAdding) {
							handleTracking(trackingEvent);
						}
						let favoriteProducts = isAdding
							? response.data.addFavoriteProduct.favoriteProducts
							: response.data.deleteFavoriteProduct.favoriteProducts;
						return from([
							UserActions.setFavoriteProducts(favoriteProducts, undefined),
							{ type: UserActions.GET_USER_FAVORITE_PRODUCTS_SUCCESS }
						]);
					}),
					catchError(error => of(UserActions.userProfileError(errorMessageOnUpdateFavorites)))
				);
			})
		);
	};

	static downloadFavoriteProductsEpic = (
		action$: Observable<any>,
		state$: StateObservable<any>
	): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.DOWNLOAD_FAVORITE_PRODUCTS),
			mergeMap(action => {
				const userId = getLocalStorageValue(IirisIdentityKey.USER_ID);
				const onErrorToastMessage: IToastState = {
					title: 'Error in Download',
					message: 'CSV download unsuccessful, please try again.',
					type: 'error',
					position: toast.POSITION.TOP_RIGHT,
					closeOnClick: false,
					autoClose: 5000
				};
				const userEmail = getLocalStorageValue(IirisIdentityKey.USER_EMAIL);

				if (userEmail && getIirisCookie()) {
					const headers = {
						email: userEmail,
						authorization: getIirisCookie() || ''
					};
					const url = `${process.env.REACT_APP_BEACON_API_URL}/profile/${userId}/favorites`;

					return from(fetch(url, { headers })).pipe(
						mergeMap(response =>
							response.blob().then(blob => {
								if (response.status === 200) {
									const a = document.createElement('a');
									a.download = 'Beacon Discovery Product Favorites';
									a.href = window.URL.createObjectURL(blob);
									const clickEvt = new MouseEvent('click', {
										view: window,
										bubbles: true,
										cancelable: true
									});
									a.dispatchEvent(clickEvt);
									a.remove();
									return of(
										UserActions.downloadFavoriteProductsSuccess({
											title: 'CSV Downloaded',
											message: 'Export successful.',
											type: 'success',
											position: toast.POSITION.TOP_RIGHT,
											closeOnClick: false,
											autoClose: 5000
										})
									);
								} else {
									return of(UserActions.downloadFavoriteProductsError(onErrorToastMessage));
								}
							})
						),
						catchError(err => of(UserActions.downloadFavoriteProductsError(onErrorToastMessage)))
					);
				} else {
					return of(UserActions.downloadFavoriteProductsError(onErrorToastMessage));
				}
			})
		);
	};
	static createUserProfileBeacon = (action$: Observable<any>): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.CREATE_USER_PROFILE_BEACON),
			mergeMap(action => {
				const { trackingEvent, popup } = action.payload;
				const toastMessageOnCreateProfile: IToastState = {
					title: 'Error during profile creation',
					message: 'Your Beacon account could not be created, please try again.',
					type: 'error',
					position: toast.POSITION.TOP_RIGHT,
					closeOnClick: false,
					autoClose: 5000
				};
				const email = getLocalStorageValue(IirisIdentityKey.USER_EMAIL);
				const token = getIirisCookie();
				const request = {
					url: `${process.env.REACT_APP_IRIS_PROFILE_END_POINT}`,
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: token
					},
					crossDomain: true,
					body: {
						email: email,
						profile_type: 'business_email'
					}
				};
				return ajax(request).pipe(
					mergeMap((result): any => {
						const { response }: any = result;
						const userProfile = response?.status === 'success' ? response?.data?.[0] : null;
						if (response?.status === 'success') {
							return from(
								apolloClient.mutate({
									mutation: CREATE_PROFILE,
									variables: { id: userProfile.id, email: email, ingredientsTemplateId: '1' }
								})
							).pipe(
								mergeMap(response => {
									setLocalStorageValue(IirisIdentityKey.USER_ID, response.data.createProfile.id);
									if (
										trackingEvent &&
										localStorage.getItem(convertUserLocalStorageKey) === 'true'
									) {
										const event = {
											...trackingEvent,
											entityId: response.data.createProfile.id,
											eventPayload: {
												...trackingEvent.eventPayload,
												id: response.data.createProfile.id,
												name: response.data.createProfile.id,
												metadata: {
													...trackingEvent.eventPayload.metadata,
													user_id: response.data.createProfile.id
												}
											}
										};
										handleTracking(event);
										localStorage.removeItem(convertUserLocalStorageKey);
									}
									return from([
										AppActions.setModalContent(popup),
										UserActions.updaUserBeaconInfoStatus(undefined)
									]);
								}),
								catchError(error => {
									console.error(error);
									return of(UserActions.setCreateBeaconProfileError(toastMessageOnCreateProfile));
								})
							);
						}
					}),
					catchError(error => {
						console.error(error);
						return of(UserActions.setCreateBeaconProfileError(toastMessageOnCreateProfile));
					})
				);
			})
		);
	};
	static setCreateUserProfileErrorEpic = (action$: Observable<any>): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.SET_CREATE_BEACON_PROFILE_ERROR),
			mergeMap(action => {
				removeAllAuthenticationKeysFromLocalStorage();
				return of(UserActions.createBeaconProfileError(action.payload));
			})
		);
	};
	static getAnonymousUserTokenEpic = (action$: Observable<any>): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.GET_ANONYMOUS_USER_TOKEN),
			mergeMap(action => {
				const request = {
					url: `${process.env.REACT_APP_BEACON_API_URL}/public/authorize`,
					method: 'POST',
					crossDomain: true
				};
				return ajax(request).pipe(
					mergeMap(({ response }: any) => {
						setLocalStorageValue(IirisIdentityKey.USER_IDENTITY, response?.token);
						return of({ type: UserActions.GET_ANONYMOUS_USER_TOKEN_SUCCESS });
					}),
					catchError(() => {
						removeAllAuthenticationKeysFromLocalStorage();
						return of({ type: UserActions.GET_ANONYMOUS_USER_TOKEN_ERROR });
					})
				);
			})
		);
	};
	static registerUserClickEventEpic = (action$: Observable<any>): Observable<any> => {
		return action$.pipe(
			ofType(UserActions.REGISTER_USER_CLICK_EVENT),
			mergeMap(action => {
				const token = getIirisCookie();
				const request = {
					url: `${process.env.REACT_APP_BEACON_API_URL}/public/registerEvent`,
					method: 'POST',
					crossDomain: true,
					headers: {
						'Content-Type': 'application/json',
						Authorization: token
					},
					body: {
						type: action.payload
					}
				};
				return ajax(request).pipe(
					mergeMap(({ response }: any) => {
						return of({ type: UserActions.REGISTER_USER_CLICK_EVENT_SUCCESS });
					}),
					catchError(error => {
						console.error(error);
						if (error.status === 401) {
							localStorage.setItem(lastUrlLocalStorageKey, window.location.pathname);
							return of({ type: UserActions.REGISTER_USER_CLICK_EVENT_ERROR });
						} else {
							return of({ type: 'END_STATE', payload: action.payload });
						}
					})
				);
			})
		);
	};
}
