import { useEffect, useState } from 'react';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
import './ProductDetailsFormComponent.scss';
import { Product } from '../../../../common/model';
interface IProductDetailsForm {
	productState: any;
	onUpdateProfileProduct: (userState: any) => void;
	onCancel: () => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	handleChange: (event: any) => void;
}
const ProductDetailsFormComponent: React.FC<IProductDetailsForm> = ({
	productState,
	onUpdateProfileProduct,
	onCancel,
	isEditing,
	toggleEditing,
	handleChange
}) => {
	const [state, setState] = useState<Product>();
	const [ID, setID] = useState<string | null>(null);

	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (productState) {
			setState(productState);
		}
	}, [productState]);
	useEffect(() => {
		if (state) {
			setID('product-details' + state.id);
		}
	}, [state]);
	return (
		<div className='product-details-form'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => {
					onUpdateProfileProduct(state);
				}}
				titleText='Product Details'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
			></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms
											text="Is This your Brand's Flagship Product?
											"
											icon={<i className='ri-question-line' />}
											toolTipText={
												<>
													Your Flagship product is a best-seller or fan favorite product from your
													brand. For Newtopia Now, this product is featured in The Market.
												</>
											}
											toolTipPosition='right'
										></LabelForms>
										<p>Yes</p>
										{/* <ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-fill' />}
											disabled
										/> */}
									</div>
									<div>
										<LabelForms text='Product Dimensions'></LabelForms>
										<p>Width (inches): 12</p>
										<p>Length (inches): 12</p>
										<p>Height (inches): 12</p>
										<p>Weight (ounces): 12</p>
									</div>
									<div>
										<LabelForms
											text='Product MSRP'
											icon={<i className='ri-question-line' />}
											toolTipText={
												<>
													Manufacturer's Suggested Retail Price - also known as list price or
													recommended retail price.
												</>
											}
										></LabelForms>
										<p>$4.87</p>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Launch Year of the Product'></LabelForms>
										<p>2023</p>
									</div>
									<div>
										<LabelForms
											text='Parent GTIN'
											toolTipText={
												<>
													A Global Trade Item Number is a unique and internationally recognized
													identifier for a product. When available, it will appear next to the
													barcode on your product's packaging.
												</>
											}
											icon={<i className='ri-question-line' />}
										></LabelForms>
										<p>4534063856837262</p>
									</div>
									<div>
										<LabelForms text='Product Shelf Life (days)'></LabelForms>
										<p>12</p>
									</div>
								</>
							}
						></ContainerForms>
					</>
				) : (
					<>
						<ActionsForms
							onUpdate={() => {}}
							onCancel={onCancel}
							toggleEditing={() => toggleEditing(null)}
						></ActionsForms>
					</>
				))}
		</div>
	);
};

export default React.memo(ProductDetailsFormComponent);
