import { Action } from 'redux-actions';
import { IToastState, ITrackingEvent } from '../common/view-model';
import { FavoriteAddDate } from './AppReducer';
import { AppState } from './AppReducer';
export class UserActions {
	static readonly GET_USER_PROFILE = '@@user/GET_USER_PROFILE';
	static readonly GET_USER_PROFILE_SUCCESS = '@@user/GET_USER_PROFILE_SUCCESS';
	static readonly GET_USER_PROFILE_ERROR = '@@user/GET_USER_PROFILE_ERROR';
	static readonly GET_USER_PROFILE_BEACON = '@@user/GET_USER_PROFILE_BEACON';
	static readonly GET_USER_PROFILE_BEACON_SUCCESS = '@@user/GET_USER_PROFILE_BEACON_SUCCESS';
	static readonly GET_USER_BRAND_PROFILE_SUCCESS = '@@user/GET_USER_BRAND_PROFILE_SUCCESS';
	static readonly GET_USER_PRODUCTS_PROFILE_SUCCESS = '@@user/GET_USER_PRODUCTS_PROFILE_SUCCESS';
	static readonly SET_INGREDIENTS_TEMPLATE_ID = '@@user/SET_INGREDIENTS_TEMPLATE_ID';
	static readonly SAVE_USER_INGREDIENTS_TEMPLATE_ID = '@@user/SAVE_USER_INGREDIENTS_TEMPLATE_ID';
	static readonly SAVE_USER_INGREDIENTS_TEMPLATE_ID_SUCCESS =
		'@@user/SAVE_USER_INGREDIENTS_TEMPLATE_ID_SUCCESS';
	static readonly SAVE_USER_INGREDIENTS_TEMPLATE_ID_ERROR =
		'@@user/SAVE_USER_INGREDIENTS_TEMPLATE_ID_ERROR';
	static readonly SET_FAVORITE_PRODUCTS = '@@user/SET_FAVORITE_PRODUCTS';
	static readonly GET_USER_FAVORITE_PRODUCTS_SUCCESS = '@@user/GET_USER_FAVORITE_PRODUCTS_SUCCESS';
	static readonly UPDATE_USER_FAVORITE_PRODUCTS = '@@user/UPDATE_USER_FAVORITE_PRODUCTS';
	static readonly UPDATE_USER_PROFILE = '@@user/UPDATE_USER_PROFILE';
	static readonly UPDATE_USER_PROFILE_SUCCESS = '@@user/UPDATE_USER_PROFILE_SUCCESS';
	static readonly UPDATE_USER_PROFILE_ERROR = '@@user/UPDATE_USER_PROFILE_ERROR';
	static readonly DOWNLOAD_FAVORITE_PRODUCTS = '@@user/DOWNLOAD_FAVORITE_PRODUCTS';
	static readonly SAVE_INGREDIENT_TEMPLATE_ERROR = '@@user/SAVE_INGREDIENT_TEMPLATE_ERROR';
	static readonly DOWLOAD_FAVORITE_PRODUCTS_ERROR = '@@user/DOWLOAD_FAVORITE_PRODUCTS_ERROR';
	static readonly DOWNLOAD_FAVORITE_PRODUCTS_SUCCESS = '@@user/DOWNLOAD_FAVORITE_PRODUCTS_SUCCESS';
	static readonly CREATE_USER_PROFILE_BEACON = '@@user/CREATE_USER_PROFILE_BEACON';
	static readonly SET_IS_LOADING_PROFILE = '@@user/SET_IS_LOADING_PROFILE';
	static readonly UPDATE_COMPANY_DETAILS_SUCCESS = '@@user/UPDATE_COMPANY_DETAILS_SUCCESS';
	static readonly SAVE_USER_BEACON_LOADING = '@@user/SAVE_USER_BEACON_LOADING';
	static readonly SET_CREATE_BEACON_PROFILE_ERROR = '@@user/SET_CREATE_BEACON_PROFILE_ERROR';
	static readonly CREATE_BEACON_PROFILE_ERROR = '@@user/CREATE_BEACON_PROFILE_ERROR';
	static readonly GET_ANONYMOUS_USER_TOKEN = '@@user/GET_ANONYMOUS_USER_PROFILE';
	static readonly GET_ANONYMOUS_USER_TOKEN_SUCCESS = '@@user/GET_ANONYMOUS_USER_PROFILE_SUCCESS';
	static readonly GET_ANONYMOUS_USER_TOKEN_ERROR = '@@user/GET_ANONYMOUS_USER_PROFILE_ERROR';
	static readonly SET_UNAUTHORIZE_ANONYMOUS_USER = '@@user/SET_UNAUTHORIZE_USER';
	static readonly CURRENT_SESSION_DETECTED = '@@user/CURRENT_SESSION_DETECTED';
	static readonly CLICK_ON_FILTER = '@@user/CLICK_ON_FILTER';
	static readonly REGISTER_USER_CLICK_EVENT = '@@user/REGISTER_USER_CLICK_EVENT';
	static readonly REGISTER_USER_CLICK_EVENT_SUCCESS = '@@user/REGISTER_USER_CLICK_EVENT_SUCCESS';
	static readonly REGISTER_USER_CLICK_EVENT_ERROR = '@@user/REGISTER_USER_CLICK_EVENT_ERROR';
	static readonly UPDATE_USER_UNSUBSCRIBE = '@@user/UPDATE_USER_UNSUBSCRIBE';
	static readonly UPDATE_USER_SUBSCRIBE = '@@user/UPDATE_USER_SUBSCRIBE';
	static readonly UPDATE_USER_SUBSCRIBE_STATUS = '@@user/UPDATE_USER_SUBSCRIBE_STATUS';
	static readonly USER_SUBSCRIBE_STATUS_SUCCESS = '@@user/USER_SUBSCRIBE_STATUS_SUCCESS';
	static readonly USER_SUBSCRIBE_STATUS_ERROR = '@@user/USER_SUBSCRIBE_STATUS_ERROR';
	static readonly UPDATE_USER_BEACON_INFO_STATUS = '@@user/UPDATE_USER_BEACON_INFO_STATUS';
	static readonly GET_USER_PROFILE_BEACON_LOGIN = '@@user/GET_USER_PROFILE_BEACON_LOGIN';

	static updateSubscribeStatus(status: boolean) {
		return {
			type: UserActions.UPDATE_USER_SUBSCRIBE_STATUS,
			payload: status
		};
	}

	static updaUserBeaconInfoStatus(status: any): Action<AppState> {
		return {
			type: UserActions.UPDATE_USER_BEACON_INFO_STATUS,
			payload: status
		};
	}

	static updateUserSubscribeSuccess(payload: IToastState) {
		return {
			type: UserActions.USER_SUBSCRIBE_STATUS_SUCCESS,
			payload
		};
	}

	static updateUserSubscribeError(payload: IToastState) {
		return {
			type: UserActions.USER_SUBSCRIBE_STATUS_ERROR,
			payload
		};
	}

	static updateUserSubscribe(profileId: any): Action<AppState> {
		return {
			type: UserActions.UPDATE_USER_SUBSCRIBE,
			payload: profileId
		};
	}

	static updateUserUnsubscribe(profileId: any): Action<AppState> {
		return {
			type: UserActions.UPDATE_USER_UNSUBSCRIBE,
			payload: profileId
		};
	}

	static getUserProfileSuccess(user: any): Action<AppState> {
		return {
			type: UserActions.GET_USER_PROFILE_SUCCESS,
			payload: user
		};
	}

	static setIngredientsTemplateId(payload: number): Action<number> {
		return {
			type: UserActions.SET_INGREDIENTS_TEMPLATE_ID,
			payload
		};
	}

	static setFavoriteProducts(
		favoriteProducts: FavoriteAddDate[],
		toastPayload?: IToastState
	): Action<any> {
		return {
			type: UserActions.SET_FAVORITE_PRODUCTS,
			payload: {
				favoriteProducts,
				toastPayload
			}
		};
	}

	static getUserProfileBeaconSuccess(companyDetails: any): Action<any> {
		return {
			type: UserActions.GET_USER_PROFILE_BEACON_SUCCESS,
			payload: companyDetails
		};
	}

	static getUserBrandProfileSuccess(brandDetails: any): Action<any> {
		return {
			type: UserActions.GET_USER_BRAND_PROFILE_SUCCESS,
			payload: brandDetails
		};
	}
	static getUserProductsProfileSuccess(products: any): Action<any> {
		return {
			type: UserActions.GET_USER_PRODUCTS_PROFILE_SUCCESS,
			payload: products
		};
	}
	static toggleFavoriteProduct(productId: string, trackingEvent?: ITrackingEvent): Action<any> {
		return {
			type: UserActions.UPDATE_USER_FAVORITE_PRODUCTS,
			payload: {
				productId,
				trackingEvent
			}
		};
	}

	static updateUserProfile(payload: any): Action<any> {
		return {
			type: UserActions.UPDATE_USER_PROFILE,
			payload
		};
	}
	static saveIngredientTemplateSuccess(payload: any): Action<any> {
		return {
			type: UserActions.SAVE_USER_INGREDIENTS_TEMPLATE_ID_SUCCESS,
			payload
		};
	}
	static updateUserProfileEpic(payload: any): Action<any> {
		return {
			type: UserActions.UPDATE_USER_PROFILE_SUCCESS,
			payload
		};
	}
	static userProfileError(payload: IToastState): Action<any> {
		return {
			type: UserActions.UPDATE_USER_PROFILE_ERROR,
			payload
		};
	}
	static saveIngredientTemplateError(payload: IToastState): Action<any> {
		return {
			type: UserActions.SAVE_INGREDIENT_TEMPLATE_ERROR,
			payload
		};
	}
	static downloadFavoriteProductsError(payload: IToastState): Action<any> {
		return {
			type: UserActions.DOWLOAD_FAVORITE_PRODUCTS_ERROR,
			payload
		};
	}
	static downloadFavoriteProductsSuccess(payload: IToastState): Action<any> {
		return {
			type: UserActions.DOWNLOAD_FAVORITE_PRODUCTS_SUCCESS,
			payload
		};
	}
	static createUserProfileBeacon(payload: any, trackingEvent: ITrackingEvent): Action<any> {
		return {
			type: UserActions.CREATE_USER_PROFILE_BEACON,
			payload: {
				popup: payload,
				trackingEvent
			}
		};
	}
	static setIsLoadingProfile(payload: boolean): Action<any> {
		return {
			type: UserActions.SET_IS_LOADING_PROFILE,
			payload
		};
	}
	static updateCompanyDetailsSuccess(payload: any): Action<any> {
		return {
			type: UserActions.UPDATE_COMPANY_DETAILS_SUCCESS,
			payload
		};
	}
	static saveUserBeaconLoading(payload: boolean): Action<any> {
		return {
			type: UserActions.SAVE_USER_BEACON_LOADING,
			payload
		};
	}
	static setCreateBeaconProfileError(payload: IToastState): Action<any> {
		return {
			type: UserActions.SET_CREATE_BEACON_PROFILE_ERROR,
			payload
		};
	}
	static createBeaconProfileError(payload: IToastState): Action<any> {
		return {
			type: UserActions.CREATE_BEACON_PROFILE_ERROR,
			payload
		};
	}
	static currentSessionDetected(payload: IToastState): Action<any> {
		return {
			type: UserActions.CURRENT_SESSION_DETECTED,
			payload
		};
	}
	static clickOnFilter(payload: boolean): Action<boolean> {
		return {
			type: UserActions.CLICK_ON_FILTER,
			payload
		};
	}
	static registerUserClickEvent(payload: string): Action<string> {
		return {
			type: UserActions.REGISTER_USER_CLICK_EVENT,
			payload
		};
	}
}
