import { useEffect, useState } from 'react';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
import './ProductCertificationsFormComponent.scss';
import { Product } from '../../../../common/model';
interface IProductCertificationsForm {
	productState: any;
}
const ProductCertificationsFormComponent: React.FC<IProductCertificationsForm> = ({
	productState
}) => {
	const [state, setState] = useState<Product>();
	const [isVisible, setVisibility] = useState<boolean>(true);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (productState) {
			setState(productState);
		}
	}, [productState]);
	return (
		<div className='product-certifications-form'>
			<TitleForms
				isVisible={isVisible}
				titleText='Certifications'
				toggleVisibility={toggleVisibility}
				readOnly
			></TitleForms>
			{isVisible && (
				<>
					<p className='helper-text'>
						To provide updated certification information, email
						<a href='mailto:Standards@newhope.com.<'> standards@newhope.com.</a>{' '}
					</p>
					<ContainerForms
						FormComponent1={
							<>
								{' '}
								<div>
									<LabelForms
										text='Certified Gluten Free
											'
									></LabelForms>
									<p>Verified</p>
								</div>
								<div>
									<LabelForms
										text='Certified Organic
											'
									></LabelForms>
									<p>Verified</p>
								</div>
							</>
						}
						FormComponent2={
							<>
								{' '}
								<div>
									<LabelForms
										text='Certified Non-GMO
											'
									></LabelForms>
									<p>Verified</p>
								</div>
							</>
						}
					></ContainerForms>
				</>
			)}
		</div>
	);
};

export default React.memo(ProductCertificationsFormComponent);
