import { FC, useState } from 'react';
import { IirisAuthenticationComponent } from '../authentication/iirisAuthenticationComponent';
import './MainPageComponent.scss';
import { LandingPageState } from './model';

export interface IMainPageComponentProps {}

export const MainPageComponent: FC<IMainPageComponentProps> = () => {
	const beaconLetters = require('./../../assets/images/beacon-letters.png');
	const [state] = useState({ ...LandingPageState });
	return (
		<div data-testid='main-page-container' className='main-page-container'>
			<div className='main-page-content'>
				<div className='col'>
					<div className='row'>
						<div className='logo'>
							<img className='beacon-letters' src={beaconLetters} alt='beacon letters' />
						</div>
					</div>
					<div className='row'>
						<span className='welcome-text'>{state.welcomeText}</span>
					</div>
					<br />
					<div className='row'>
						<span className='login-text'>{state.loginMessage}</span>
					</div>
					<div className='row'>
						<IirisAuthenticationComponent />
					</div>
				</div>
			</div>
		</div>
	);
};
