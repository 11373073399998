import { gql } from 'graphql.macro';

export const GET_PROFILE = gql`
	query GetProfile($id: ID!) {
		getProfile(id: $id) {
			id
			email
			companyDetails {
				jobType
				name
				userType
				website
			}
			favoriteProducts {
				pid
				addDate
			}
			ingredientsTemplateId
		}
	}
`;
