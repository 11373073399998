import './SocialMediaFormComponent.scss';
import { useEffect, useState } from 'react';
import { BrandInfo } from '../../../../redux/AppReducer';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';

interface ISocialMediaForm {
	brandState: any;
	onUpdateProfileBrand: (userState: any) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	handleChange: (event: any) => void;
	onCancel: () => void;
}
const SocialMediaFormComponent: React.FC<ISocialMediaForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing,
	handleChange
}) => {
	const [state, setState] = useState<BrandInfo>();
	const ID = 'social-media-details';
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (brandState) {
			setState(brandState);
		}
	}, [brandState]);
	return (
		<div className='social-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => onUpdateProfileBrand(state)}
				titleText='Social Media'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
			></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms text='YouTube'></LabelForms>
										<p className={state?.brand_youtube ? '' : 'blank-field'}>
											{state?.brand_youtube}
										</p>
									</div>
									<div>
										<LabelForms text='Instagram'></LabelForms>
										<p className={state?.brand_instagram ? '' : 'blank-field'}>
											{state?.brand_instagram}
										</p>
									</div>
									<div>
										<LabelForms text='TikTok'></LabelForms>
										<p className={state?.brand_tiktok ? '' : 'blank-field'}>
											{state?.brand_tiktok}
										</p>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Twitter / X'></LabelForms>
										<p className={state?.brand_twitter ? '' : 'blank-field'}>
											{state?.brand_twitter}
										</p>
									</div>
									<div>
										<LabelForms text='Facebook'></LabelForms>
										<p className={state?.brand_facebook ? '' : 'blank-field'}>
											{state?.brand_facebook}
										</p>
									</div>
									<div>
										<LabelForms text='LinkedIn'></LabelForms>
										<p className={state?.brand_linkedin ? '' : 'blank-field'}>
											{state?.brand_linkedin}
										</p>
									</div>
								</>
							}
						/>
					</>
				) : (
					<>
						<ActionsForms
							onUpdate={() => onUpdateProfileBrand(state)}
							onCancel={onCancel}
							toggleEditing={() => toggleEditing(null)}
						></ActionsForms>
					</>
				))}
		</div>
	);
};

export default React.memo(SocialMediaFormComponent);
