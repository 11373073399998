import { useDispatch, useSelector } from 'react-redux';
import { FC, useCallback, useEffect } from 'react';
import { IrisAuthenticationState } from './model';
import { useState } from 'react';
import './iirisAuthenticationComponent.scss';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/AppReducer';
import { UserMenuComponent } from './user-menu/userMenuComponent';
import { UserActions } from '../../redux/UserActions';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import CompanyDetailsComponent from './company-details/CompanyDetailsComponent';
import { AppActions } from '../../redux/AppActions';
import {
	ITrackingEvent,
	ScreenType,
	TrackingEventStatus,
	TrackingEventType
} from '../../common/view-model';
import {
	convertUserLocalStorageKey,
	eventPublicPathName,
	lastUrlLocalStorageKey
} from '../../common/constants';
import {
	IirisIdentityKey,
	getLocalStorageValue,
	validateAnonymousPath
} from '../../helpers/authenticationHelper';
import { v4 as uuidv4 } from 'uuid';

interface IIirisAuthenticationProps {
	mobileView?: boolean;
	eventLink?: string;
}

export const IirisAuthenticationComponent: FC<IIirisAuthenticationProps> = ({
	mobileView,
	eventLink
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loadProfileProgress } = useSelector((state: RootState) => state.appState);
	const isAuthenticated = useSelector(
		(state: RootState) => state.appState.authState?.isAuthenticated
	);
	const isRefreshingToken = useSelector(
		(state: RootState) => state.appState.authState?.isRefreshingToken
	);
	const userState = useSelector((state: RootState) => state.appState.userState);
	const modalContent = useSelector((state: RootState) => state.appState.modalContent);
	const beaconProfileVerify = useSelector((state: RootState) => state.appState.userBeaconInfo);
	const [state, setState] = useState({ ...IrisAuthenticationState, isAuthenticated });
	const diviceScreen = useSelector((state: RootState) => state.appState.deviceScreen);
	const handleShowMenu = () => {
		setState({ ...state, showMenu: !state.showMenu });
	};
	const [userIconState, setUserIconState] = useState('white');
	const handleIconHover = () => {
		setUserIconState('blue');
	};
	const handleIconLeave = () => {
		if (state.showMenu) {
			setUserIconState('blue');
		} else {
			setUserIconState('white');
		}
	};
	const onMenuClose = () => {
		setState({ ...state, showMenu: false });
		setUserIconState('white');
	};
	const isLoadingBeaconUserSave = useSelector(
		(state: RootState) => state.appState.isLoadingSaveUserBeacon
	);
	const finishProgressCallback = useCallback(() => {
		return dispatch(AppActions.finishProgress());
	}, [dispatch]);
	const ctaLinkAddress = `${window.location.origin}/${eventPublicPathName}/getonbeacon`;
	const ctaRedirectCallBack = useCallback(() => {
		window.open(ctaLinkAddress, '_blank', 'noopener noreferrer');
	}, []);
	useEffect(() => {
		document.addEventListener('mouseup', function (event) {
			const obj = document.getElementById('user-menu');
			if (obj && !obj.contains(event.target as any)) {
				setState({ ...state, showMenu: false });
				setUserIconState('white');
			}
		});
	}, []);

	useEffect(() => {
		if (isRefreshingToken === true) {
			return;
		}
		if (
			isAuthenticated &&
			!userState?.user &&
			!validateAnonymousPath(eventPublicPathName) &&
			isRefreshingToken !== undefined
		) {
			dispatch({ type: UserActions.GET_USER_PROFILE });
		}
	}, [isAuthenticated, isRefreshingToken]);
	useEffect(() => {
		if (
			isAuthenticated &&
			userState?.hasLoadProfile &&
			window.location.pathname === '/landing' &&
			loadProfileProgress === 100
		) {
			navigate(localStorage.getItem(lastUrlLocalStorageKey) || '/home');
			localStorage.removeItem(lastUrlLocalStorageKey);
		}
	}, [isAuthenticated, userState?.hasLoadProfile, userState, loadProfileProgress, navigate]);
	useEffect(() => {
		if (isAuthenticated && modalContent === undefined && window.location.pathname === '/') {
			navigate('/landing');
		}
	}, [isAuthenticated]);
	const createBeaconProfileCallback = useCallback(() => {
		const trackingPayload: ITrackingEvent = {
			transactionId: uuidv4(),
			entityId: getLocalStorageValue(IirisIdentityKey.USER_ID) || '',
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.PUBLIC_USER_CONVERTED,
			timeStamp: new Date().toISOString(),
			eventPayload: {
				id: getLocalStorageValue(IirisIdentityKey.USER_ID) || '',
				name: getLocalStorageValue(IirisIdentityKey.USER_ID) || '',
				type: TrackingEventType.PUBLIC_USER_CONVERTED,
				url: window.location.href,
				tags: [],
				metadata: {
					user_id: getLocalStorageValue(IirisIdentityKey.USER_ID) || '',
					enviroment: process.env.REACT_APP_ENV_NAME
				}
			}
		};
		return dispatch(
			UserActions.createUserProfileBeacon(
				{
					content: <CompanyDetailsComponent />,
					showCloseIcon: true,
					canScroll: true,
					isCompanyDetailsModal: true,
					closeOnDocumentClick: false
				},
				trackingPayload
			)
		);
	}, [dispatch]);
	const loadingUserStateBeaconCallback = useCallback(
		(loading: boolean) => {
			return dispatch(UserActions.saveUserBeaconLoading(loading));
		},
		[dispatch]
	);
	const verifyProfile = useCallback(() => {
		dispatch({ type: UserActions.GET_USER_PROFILE_BEACON_LOGIN });
	}, [dispatch]);

	useEffect(() => {
		if (beaconProfileVerify === false && window.location.pathname === '/') {
			createBeaconProfileCallback();
		} else {
			if (beaconProfileVerify === true && window.location.pathname === '/') {
				dispatch({ type: UserActions.updaUserBeaconInfoStatus(undefined) });
				window.location.href = '/landing';
			}
		}
	}, [beaconProfileVerify]);

	useEffect(() => {
		window.addEventListener('beacon-loading-state', () => loadingUserStateBeaconCallback(true));
		window.addEventListener('iris-register-success', createBeaconProfileCallback);
		window.addEventListener('iris-check-profile', verifyProfile);
		return () => {
			window.removeEventListener('iris-register-success', createBeaconProfileCallback);
			window.removeEventListener('beacon-loading-state', () =>
				loadingUserStateBeaconCallback(true)
			);
			window.removeEventListener('iris-check-profile', verifyProfile);
		};
	}, []);
	useEffect(() => {
		if (userState?.hasLoadProfile && loadProfileProgress === 90) {
			finishProgressCallback();
		}
	}, [userState?.hasLoadProfile, finishProgressCallback, loadProfileProgress]);
	const handleLogin = () => {
		if (validateAnonymousPath(eventPublicPathName)) {
			localStorage.setItem(lastUrlLocalStorageKey, window.location.pathname);
			navigate('/');
		}
		state.handleLogin();
	};
	const handleRegister = () => {
		if (validateAnonymousPath(eventPublicPathName)) {
			localStorage.setItem(lastUrlLocalStorageKey, window.location.pathname);
			localStorage.setItem(convertUserLocalStorageKey, 'true');
			navigate('/');
		}
		state.handleRegister();
	};

	return (
		<div className='authentication' data-testid='authentication'>
			{isAuthenticated ? (
				<>
					<div className='col'>
						<div className='row'>
							<div
								data-testid='user-icon'
								className={`user-icon ${
									state.showMenu && diviceScreen !== ScreenType.MOBILE ? ' active' : ''
								}`}
								onClick={handleShowMenu}
								onMouseEnter={() => diviceScreen !== ScreenType.MOBILE && handleIconHover()}
								onMouseLeave={handleIconLeave}
							>
								<i
									data-testid='ri-user-line'
									className={`${
										diviceScreen !== ScreenType.MOBILE ? 'ri-user-line' : 'ri-menu-line'
									} ${diviceScreen !== ScreenType.MOBILE ? 'blue' : 'white'} ${userIconState}${
										state.showMenu ? ' active' : ''
									}`}
								></i>
							</div>
						</div>
						<div className='row' data-testid='user-menu'>
							{state.showMenu && (
								<UserMenuComponent
									onClose={onMenuClose}
									mobileView={mobileView}
									eventLink={eventLink}
								/>
							)}
						</div>
					</div>
				</>
			) : (
				<>
					<div
						className={`buttons-section ${
							validateAnonymousPath(eventPublicPathName) ? 'anonymous-container' : ''
						}`}
						data-testid='buttons-section'
					>
						<ButtonComponent
							text={state.loginButtonText}
							onClick={handleLogin}
							style={'rounded-button primary'}
							disabled={isLoadingBeaconUserSave}
						/>
						<ButtonComponent
							text={state.registerButtonText}
							onClick={handleRegister}
							style={'rounded-button secondary'}
							disabled={isLoadingBeaconUserSave}
						/>
					</div>
					{!validateAnonymousPath(eventPublicPathName) && (
						<a href={ctaLinkAddress} className='cta-container' target='blank'>
							<ButtonComponent
								style='rounded-button tertiary'
								text={state.ctaButtonText}
								onClick={ctaRedirectCallBack}
							/>
						</a>
					)}
				</>
			)}
		</div>
	);
};
