import './DistributionFormComponent.scss';
import { useEffect, useState } from 'react';
import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import { BrandInfo } from '../../../../redux/AppReducer';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
interface IDistributionForm {
	brandState: any;
	onUpdateProfileBrand: (userState: any) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	handleChange: (event: any) => void;
	onCancel: () => void;
}
const DistributionFormComponent: React.FC<IDistributionForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing,
	handleChange
}) => {
	const [state, setState] = useState<BrandInfo>();
	const ID = 'distribution-channels';
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (brandState) {
			setState(brandState);
		}
	}, [brandState]);
	return (
		<div className='profile-distribution'>
			<div className='distribution-tab'>
				<TitleForms
					isEditing={isEditing === ID}
					isVisible={isVisible}
					onUpdate={() => onUpdateProfileBrand(state)}
					titleText='Distribution / Channels'
					toggleEditing={() => toggleEditing(ID)}
					toggleVisibility={toggleVisibility}
				></TitleForms>
				{isVisible &&
					(isEditing !== ID ? (
						<>
							<ContainerForms
								FormComponent1={
									<>
										{' '}
										<div>
											<LabelForms text='Are you Currently in Distribution?'></LabelForms>
											<ButtonComponent
												onClick={() => {}}
												style='rounded-button tertiary toggle-button'
												text=''
												icon={<i className='ri-toggle-fill' />}
												disabled
											/>
										</div>
										<div>
											<LabelForms text='Which Channels are you in Distribution?'></LabelForms>
											<div className='checkbox-container'>
												<i className='ri-checkbox-fill' />
												<p>KeHE</p>
											</div>
											<div className='checkbox-container'>
												<i className='ri-checkbox-fill' />
												<p>Other</p>
											</div>
										</div>
										<div>
											<LabelForms text='For KeHE, which Regions are you Available in?'></LabelForms>
											<div className='checkbox-container'>
												<i className='ri-checkbox-fill' />
												<p>North East</p>
											</div>
											<div className='checkbox-container'>
												<i className='ri-checkbox-fill' />
												<p>East</p>
											</div>
											<div className='checkbox-container'>
												<i className='ri-checkbox-fill' />
												<p>South East</p>
											</div>
										</div>
									</>
								}
								FormComponent2={
									<>
										{' '}
										<div>
											<LabelForms text='Do you Have a MAP Policy?'></LabelForms>
											<ButtonComponent
												onClick={() => {}}
												style='rounded-button tertiary toggle-button'
												text=''
												icon={<i className='ri-toggle-line' />}
												disabled
											/>
										</div>
										<div>
											<LabelForms text='For Other Distributors, which Regions are you Available in?'></LabelForms>
											<div className='checkbox-container'>
												<i className='ri-checkbox-fill' />
												<p>North East</p>
											</div>
											<div className='checkbox-container'>
												<i className='ri-checkbox-fill' />
												<p>East</p>
											</div>
											<div className='checkbox-container'>
												<i className='ri-checkbox-fill' />
												<p>South East</p>
											</div>
										</div>
									</>
								}
							></ContainerForms>
						</>
					) : (
						<>
							<ActionsForms
								onUpdate={() => onUpdateProfileBrand(state)}
								onCancel={onCancel}
								toggleEditing={() => toggleEditing(null)}
							></ActionsForms>
						</>
					))}
			</div>
		</div>
	);
};

export default React.memo(DistributionFormComponent);
