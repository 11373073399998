import { newHopeAudienceEmail } from '../../../common/constants';

const DEFAULT_STATE: IFirsTimeExpoState = {
	title: 'Fast track your onboarding!',
	headerPrevText:
		'New Hope Network will complete Step 3 of this process on your brand’s behalf if you give consent in the Standards form in Step 1 or email',
	headerNextText: 'and say “I want New Hope to upload product data for me."',
	headerEmailLink: newHopeAudienceEmail,
	firstCardTitle:
		'NEW – This is my first time exhibiting at an Expo or my first time in over 1 year',
	firstCardPrevText: 'Complete New Exhibitor Standards form',
	firstCardPostText: '& once approved, book your booth',
	firstCardLinkText: 'here',
	firstCardLink: 'https://standards.newhope.com/new-exhibitor-application/',
	firstCardContentText: 'Check ‘Yes’ on the consent page to fast track your onboarding. ',
	secondCardTitle:
		'RETURNING – I exhibited at Expo East 2023 or Expo West 2024 but don’t have my products on Beacon yet',
	secondCardPrevText: 'Book your booth then access Returning Exhibitor Standards form',
	secondLinkText: 'here',
	secondCardLink: 'https://survey.alchemer.com/s3/7144903/e792d581d6a7',
	secondCardEmail: newHopeAudienceEmail,
	secondCardContentText:
		'Check ‘Yes’ on the consent page to fast track your onboarding. If you think you may have completed this form before, email',

	thirdCardPrevText:
		'Create an account for New Hope Network’s Product Portal offered by Pinto/SPINS',
	thirdCardLinkText: 'here',
	thirdCardContentText:
		'Once your account is created, you will receive your login credentials via email.',
	thirdCardLink: 'https://app.pinto.co/sign-up/request?partnerName=new-hope-network',
	fourthCardPrevText: 'Upload product data and images into Product Portal for review',
	fourthCardLinkText: 'here',
	fourthCardLink: 'https://survey.alchemer.com/s3/7555625/NHN-Consents-to-Onboard-with-SPINS-Pinto',
	fourthCardContentBoldText:
		'Fast track your onboarding and let us upload your product data for you!',
	fourthCardContentPrev: 'Give consent in the Standards form or email',
	fourthCardEmail: newHopeAudienceEmail,
	fourthCardContentPostText: 'and say “I want New Hope to upload product data for me.”',
	fiveCardHeaderText: 'See your products on Beacon Discovery',
	fiveCardContentText:
		'Once approved by our Standards team, your brand can be discovered by retailers on Beacon immediately!'
};

interface IFirsTimeExpoState {
	title: string;
	headerPrevText: string;
	headerNextText: string;
	headerEmailLink: string;
	firstCardTitle: string;
	firstCardPrevText: string;
	firstCardPostText: string;
	firstCardLinkText: string;
	firstCardLink: string;
	firstCardContentText: string;

	secondCardTitle: string;
	secondCardPrevText: string;
	secondLinkText: string;
	secondCardLink: string;
	secondCardEmail: string;
	secondCardContentText: string;

	thirdCardPrevText: string;
	thirdCardLinkText: string;
	thirdCardLink: string;
	thirdCardContentText: string;

	fourthCardPrevText: string;
	fourthCardLinkText: string;
	fourthCardLink: string;
	fourthCardContentPrev: string;
	fourthCardContentPostText: string;
	fourthCardContentBoldText: string;
	fourthCardEmail: string;

	fiveCardHeaderText: string;
	fiveCardContentText: string;
}
export const FirstTimeExpoModel = {
	...DEFAULT_STATE
};
