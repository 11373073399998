import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface IPrivateRouteProps {
	isLogged: boolean | undefined;
	hasCompanyDetails?: boolean;
}

export const PrivateRoute: FC<IPrivateRouteProps> = ({ isLogged, hasCompanyDetails }) => {
	if (isLogged) {
		if (!hasCompanyDetails && window.location.pathname !== '/company') {
			return <Navigate to='/company' />;
		} else {
			return <Outlet />;
		}
	} else {
		return <Navigate to='/' />;
	}
};
