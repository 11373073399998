import { IBrandOnboardContent } from '../../common/view-model';
const DEFAULT_STATE: IBrandPageModel = {
	firstTimeComponentProps: {
		id: 'first-time',
		title: 'It’s my first time at Expo and/or uploading my products',
		description:
			'Includes those who exhibited at EE ‘23 or EW ‘24 with no products on Beacon Discovery.',
		active: false
	},
	needChangesComponentProps: {
		id: 'need-changes',
		title: 'I have products on Beacon now and need to make changes',
		description: 'For those who already have products on Beacon Discovery.',
		active: false
	},
	beaconOnlyComponentProps: {
		id: 'beacon-only-brand',
		title: 'I’m a Beacon-only brand and not exhibiting at Expos',
		description: 'For those who are applying to be on Beacon Discovery only.',
		active: false
	}
};
interface IBrandContentProps extends Omit<IBrandOnboardContent, 'content'> {}
interface IBrandPageModel {
	firstTimeComponentProps: IBrandContentProps;
	needChangesComponentProps: IBrandContentProps;
	beaconOnlyComponentProps: IBrandContentProps;
}
export const BrandOnboardPageModel = {
	...DEFAULT_STATE
};
