import { useState, FC, MouseEvent } from 'react';
import { handleTracking } from '../../../helpers/handelTracking';
import { v4 as uuidv4 } from 'uuid';
import { TrackingEventStatus, TrackingEventType } from '../../../common/view-model';
import { RootState } from '../../../redux/AppReducer';
import { useSelector } from 'react-redux';
import { ProductVM } from '../../../common/view-model';
import { howGoodState, IHowGood } from './model';
import { APP_NAME, publicProductDetailsActions } from '../../../common/constants';
import { useDispatch } from 'react-redux';
import { AppActions } from '../../../redux/AppActions';
import './HowGoodScoreComponent.scss';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
const howGoodLogo = require('./../../../assets/images/howGood_logo.png');
interface IHowGoodScoreProps {
	score: number;
	product: ProductVM | undefined;
	initialOpen?: boolean;
	categoryAverage?: number;
	range?: string;
	registerUserClickEvent: (event: string) => void;
}
const metrics: any = [
	{
		text: 'Greenhouse Gas Emissions (GHGs)',
		icon: require('./../../../assets/images/icons/cloud.png'),
		justify: 'left'
	},
	{
		text: 'Soil Health',
		icon: require('./../../../assets/images/icons/health.png'),
		justify: 'center'
	},
	{
		text: 'Water Usage',
		icon: require('./../../../assets/images/icons/water.png'),
		justify: 'left'
	},
	{
		text: 'Land Use',
		icon: require('./../../../assets/images/icons/land.png'),
		justify: 'center'
	},
	{
		text: 'Biodiversity',
		icon: require('./../../../assets/images/icons/biodiversity.png'),
		justify: 'left'
	},
	{
		text: 'Animal Welfare',
		icon: require('./../../../assets/images/icons/animal.png'),
		justify: 'center'
	},
	{
		text: 'Labor Risk Exposure',
		icon: require('./../../../assets/images/icons/labor.png'),
		justify: 'left'
	},
	{
		text: 'Processing',
		icon: require('./../../../assets/images/icons/processing.png'),
		justify: 'center'
	}
];
export const HowGoodScore: FC<IHowGoodScoreProps> = ({
	score,
	product,
	initialOpen = false,
	categoryAverage,
	range,
	registerUserClickEvent
}) => {
	const dispatch = useDispatch();
	const [open] = useState(initialOpen);
	const [hover, setHover] = useState(false);
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const [state] = useState<IHowGood>({ ...howGoodState });
	const invalidScoreName = 'standards not met';
	const handleClickHere = (e: MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		handleTracking({
			transactionId: uuidv4(),
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.HOW_GOOD,
			timeStamp: new Date().toDateString(),
			entityId: product?.id!,
			eventPayload: {
				id: product?.id!,
				name: product?.name!,
				type: product?.taxonomy[0].name!,
				url: window.location.href,
				tags: [],
				metadata: {
					user_email: userState?.business_email,
					user_firstName: userState?.first_name,
					user_lastName: userState?.last_name,
					user_type: userState?.user_type,
					environment: process.env.REACT_APP_ENV_NAME
				}
			}
		});

		window.open('https://howgood.com/new-hope', '_blank', 'noopener,noreferrer');
	};
	const handleShow = (score: number) => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDetailsActions.CLICK_HG_SCORE);
		}
		dispatch(
			AppActions.setModalContent({
				content: (
					<HowGoodScore
						score={score}
						product={product}
						initialOpen={true}
						registerUserClickEvent={registerUserClickEvent}
					/>
				),
				showCloseIcon: true,
				canScroll: true,
				closeOnDocumentClick: true
			})
		);
	};

	return (
		<>
			{!initialOpen && (
				<>
					<div
						data-testid='how-good-score-component'
						className={`score-container ${range?.trim().toLowerCase()}`}
					>
						<div className={hover ? 'score-collapsed hover' : 'score-collapsed'}>
							<div className='hg-content-container'>
								<div
									className={`hg-title-container ${
										range?.trim().toLowerCase() === invalidScoreName.trim().toLowerCase()
											? 'hg-none-title-wrapper'
											: ''
									}`}
								>
									<div className='score-img'>
										<img src={howGoodLogo} alt='' />
									</div>
									<div className={`score-label ${range?.toLowerCase()}`}>
										<i className='ri-earth-line'></i>
										{range?.trim().toLowerCase() !== invalidScoreName.trim().toLowerCase() && (
											<b className='hg-range'>{range}</b>
										)}
										HowGood Sustainability Impact Estimate
									</div>
									<div className='question-logo'>
										<i
											data-testid='how-good-score-question-icon'
											className='ri-question-line question-icon'
											onClick={() => {
												handleShow(score);
											}}
										></i>
									</div>
								</div>
								<div
									className={`hg-content ${
										range?.trim().toLowerCase() === invalidScoreName.trim().toLowerCase()
											? 'hg-none-wrapper'
											: ''
									}`}
								>
									<div className='hg-text'>
										{range?.trim().toLowerCase() === invalidScoreName.trim().toLowerCase() ? (
											<div className='hg-explanation none-wrapper'>
												<p className='hg-explanation-text'>
													{state.getHgText(state.getPercentage(range!))}
												</p>
											</div>
										) : (
											<div className='hg-explanation'>
												<p className='hg-explanation-text'>
													{state.hgPrevText}
													<b>{state.getHgText(state.getPercentage(range!))}</b>
													{state.hgNextText}
												</p>
											</div>
										)}
										<div
											className={`estimate-container ${
												range?.trim().toLowerCase() === invalidScoreName.trim().toLowerCase()
													? 'hg-none-estimate-wrapper'
													: ''
											}`}
										>
											<div className='product-estimate'>
												<span className='estimate-text'>{state.estimateText}</span>
												<div className='estimate-value-container'>
													<i className='ri-shopping-basket-line'></i>
													<span className='estimate-value'>{score}</span>
												</div>
											</div>
											<span className='versus-text'>{state.versusText}</span>
											<div className='category-average'>
												<span className='average-text'>{state.categoryAverageText}</span>
												<div className='average-value-container'>
													<i className='ri-organization-chart'></i>
													<span className='average-value'>{categoryAverage}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			{initialOpen && (
				<div data-testid='initial-open' className={'modalopen score-container'}>
					<div
						data-testid='score-open-container'
						className={'score-collapsed'}
						onMouseOver={() => setHover(!open)}
						onClick={() => setHover(false)}
						onMouseLeave={() => setHover(false)}
					>
						<div className='score-img'>
							<img src={howGoodLogo} alt='' />
						</div>
						<div className='score-label'>HowGood Sustainability Impact Estimate</div>
					</div>
					<div className={'score-description active'}>
						<div className='score-desc-block'>
							<div className='body'>
								<b>
									<a href='https://howgood.com/new-hope' target='blank'>
										HowGood
									</a>
								</b>{' '}
								is an independent research company with the world’s largest food product
								sustainability database. Drawing from over 600 data sources and certifications,
								HowGood helps leading brands and retailers measure and improve their environmental
								and social impact.
								<br />
								<br />
								The <b>HowGood Sustainability Impact Estimate</b> measures the overall
								sustainability of a product, based on <b>8 key metrics:</b>
							</div>
							<div className='key-metrics'>
								{metrics.map((metric: any, index: number) => (
									<div key={index} className={'flex ' + metric.justify}>
										<div>
											<img className='fa' src={metric.icon} alt='icon' />
										</div>
										<div className='key-metric'>{metric.text}</div>
									</div>
								))}
							</div>
							<div className='scoring'>
								Scoring on {APP_NAME} is on a spectrum from negative to positive impact and based on
								products in the HowGood database.
								<div className='score-impact'>
									<div className='score-bar'></div>
									<div className='bar-flex'>
										<div className='goodbar'></div>
										<div className='greatbar'></div>
										<div className='bestbar'></div>
									</div>
									<div className='flex'>
										<label className='good'>
											<p>Good</p>
										</label>
										<label className='great'>
											<p>Great</p>
										</label>
										<label className='best'>
											<p>Best</p>
										</label>
									</div>
								</div>
								<b>{howGoodState.good.title}</b> {howGoodState.good.text}
								<br />
								<b>{howGoodState.great.title}</b> {howGoodState.great.text}
								<br />
								<b>{howGoodState.best.title}</b> {howGoodState.best.text}
							</div>
							<div className='footer'>
								Learn more about measuring your impact with HowGood
								<a data-testid='learn-more-link' onClick={handleClickHere}>
									here
								</a>
							</div>
							<div className='updated-info'>
								Estimates as of March 2023. Estimates updated annually.
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
