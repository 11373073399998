export interface IMenuItemOptions {
	[key: number]: number | IMenuItem;
}

export interface IMenuItem {
	title: string;
	options?: IMenuItemOptions;
}

export interface IMenuProps {
	menuItems?: IMenuItem;
	onSelectedItem: (index: any) => void;
	selectedItem: string;
}
export const DEFAULT_USER_MENU: IMenuItemOptions = {
	1: { title: 'Profile' },
	2: { title: 'Ingredient Templates' },
	3: { title: 'Favorites' }
};

export const USER_ADMIN_MENU: IMenuItemOptions = {
	1: { title: 'Profile' },
	2: { title: 'Ingredient Templates' },
	3: { title: 'Favorites' },
	4: {
		title: 'My Brand on Beacon',
		options: {
			5: { title: 'Getting Started' },
			6: { title: 'Brand Info' },
			7: { title: 'Product Info' }
		}
	}
};
