import { useEffect, useState } from 'react';
import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import { HowGoodScore } from '../../../product-detail/howGoodScore/HowGoodScoreComponent';
import { Product } from '../../../../../../../common/model';

import React from 'react';
import './ProductSustainabilityFormComponent.scss';
interface IProductDetailsForm {
	productState: any;
	onUpdateProfileProduct: (userState: any) => void;
	onCancel: () => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	handleChange: (event: any) => void;
	showHowGoodCard?: boolean;
}
const ProductDetailsFormComponent: React.FC<IProductDetailsForm> = ({
	productState,
	onUpdateProfileProduct,
	onCancel,
	isEditing,
	toggleEditing,
	handleChange,
	showHowGoodCard
}) => {
	const [state, setState] = useState<Product>();
	const [ID, setID] = useState<string | null>(null);
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (productState) {
			setState(productState);
		}
	}, [productState]);
	useEffect(() => {
		if (state) {
			setID('product-sustainability' + state.id);
		}
	}, [state]);
	return (
		<div className='product-sustainability-form'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => onUpdateProfileProduct(state)}
				titleText='Sustainability'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
			></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						{showHowGoodCard ? (
							<>
								<p className='helper-text'>
									Example of what your HowGood Sustainability Impact Estimate would look like for
									retailers:
								</p>
								<HowGoodScore
									product={{
										...state,
										allergens: [],
										attributes: [],
										brand: { processingTechniques: [{}], regionsBydistributor: [] },
										certifications: [],
										distributor: {},
										imageUrl: '',
										ingredients: [],
										netContent: '',
										region: {},
										taxonomy: []
									}}
									registerUserClickEvent={() => {}}
									score={30}
								></HowGoodScore>
							</>
						) : (
							<></>
						)}

						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms
											text='Show HowGood Estimate?
											'
											icon={<i className='ri-question-line' />}
											toolTipText={
												<>
													Toggling this on means the 'Product HowGood Estimate' and 'HowGood Label'
													fields will be visible to users on Beacon Discovery.
												</>
											}
										></LabelForms>
										<ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-fill' />}
											disabled
										/>
									</div>
									<div>
										<LabelForms text='HowGood Label'></LabelForms>
										<p>Good</p>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Product HowGood Estimate'></LabelForms>
										<p>56</p>
									</div>
									<div>
										<LabelForms text="HowGood Avg Estimate for Product's Category"></LabelForms>
										<p>55</p>
									</div>
								</>
							}
						></ContainerForms>
					</>
				) : (
					<>
						<ActionsForms
							onUpdate={() => {}}
							onCancel={onCancel}
							toggleEditing={() => toggleEditing(null)}
						></ActionsForms>
					</>
				))}
		</div>
	);
};

export default React.memo(ProductDetailsFormComponent);
