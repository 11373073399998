import { ErrorState } from '../redux/AppReducer';

export const APP_NAME = 'Beacon Discovery™';
export const factPanelIdentifier = /(fact(\s*panel|\-panel|panel)?|nutrition)/i;
export const serverError: ErrorState = {
	hasError: true,
	errorTitle: 'Something went wrong on our end, let’s get you back to discovering.',
	errorImg: require('../assets/images/ErrorState404.png')
};
export const beaconFaqLink = 'https://beacon.newhope.com/faq/';
export const eventPublicPathName = 'public';
export const expoWestLink = 'https://www.expowest.com';
export const newtopiaLink = 'https://www.newtopianow.com/en/home.html';
export const convertUserLocalStorageKey = 'convert-user';
export const publicProductDiscoveryActions = {
	CLICK_OPEN_FILTER: 'CLICK_PRODUCT_DISCOVERY_OPEN_FILTER',
	CLICK_FILTER_CHECKBOX: 'CLICK_PRODUCT_DISCOVERY_FILTER_CHECKBOX',
	CLICK_CATEGORY_TOP_NAVBAR: 'CLICK_PRODUCT_DISCOVERY_CATEGORY_TOP_NAVBAR',
	CLICK_INSIDE_TOP_NAVBAR: 'CLICK_PRODUCT_DISCOVERY_INSIDE_TOP_NAVBAR',
	CLICK_HEART_ICON: 'CLICK_PRODUCT_DISCOVERY_HEART_ICON',
	CLICK_SORT_BY: 'CLICK_PRODUCT_DISCOVERY_SORT_BY',
	SEARCH_MADE: 'CLICK_PRODUCT_DISCOVERY_SEARCH_MADE',
	CLICK_PRODUCT: 'CLICK_PRODUCT_DISCOVERY_PRODUCT_CARD',
	CLICK_INFO_NOT_AVAILABLE_MODAL: 'CLICK_PRODUCT_DISCOVERY_INFO_NOT_AVAILABLE_MODAL'
};
export const publicProductDetailsActions = {
	CLICK_PRODUCT_ACCORDION: 'CLICK_PRODUCT_DETAILS_PRODUCT_ACCORDION',
	CLICK_PRODUCT_IMAGE_CAROUSEL: 'CLICK_PRODUCT_DETAILS_PRODUCT_IMAGE_CAROUSEL',
	CLICK_HEART_ICON: 'CLICK_PRODUCT_DETAILS_HEART_ICON',
	CLICK_HG_SCORE: 'CLICK_PRODUCT_DETAILS_HG_SCORE',
	CLICK_REQUEST_SAMPLE: 'CLICK_PRODUCT_DETAILS_REQUEST_SAMPLE',
	CLICK_CONTACT_BRAND: 'CLICK_PRODUCT_DETAILS_CONTACT_BRAND',
	CLICK_CERTIFICATION_CARD: 'CLICK_PRODUCT_DETAILS_CERTIFICATION_CARD',
	CLICK_GO_TO_BRAND_PAGE: 'CLICK_PRODUCT_DETAILS_GO_TO_BRAND_PAGE'
};
export const publicBrandDetailActions = {
	CLICK_BRAND_LINKS: 'CLICK_BRAND_DETAIL_BRAND_LINKS',
	CLICK_SEE_ALL_PRODUCTS: 'CLICK_BRAND_DETAIL_SEE_ALL_PRODUCTS',
	CLICK_HEART_ICON: 'CLICK_BRAND_DETAIL_HEART_ICON'
};
export const publicHomePageActions = {
	CLICK_ALL_CATEGORIES: 'CLICK_ALL_CATEGORIES',
	CLICK_CATEGORY_BUBBLE: 'CLICK_CATEGORY_BUBBLE',
	CLICK_NEW_TO_BEACON: 'CLICK_NEW_TO_BEACON',
	CLICK_NEW_TO_MARKET: 'CLICK_NEW_TO_MARKET'
};
export const lastUrlLocalStorageKey = 'lastUrl';
export const newHopeAudienceEmail = 'AudienceSuccessTeam@newhope.com';
