import './UserProfile.scss';
import { connect, useSelector } from 'react-redux';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { UserProfileData, CountryType } from './model';
import { UserType } from '../../../common/model';
import FavoritesPage from '../../../pages/Favorites';
import ProfileIngredientComponent from '../../profile-ingredient/ProfileIngredientComponent';
import { UserActions } from '../../../redux/UserActions';
import { useDispatch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppActions } from '../../../redux/AppActions';
import { EngagementDashboardState, RootState } from '../../../redux/AppReducer';
import { applicationRoleTypes, applicationJobTypesMap, JobTypeKeys } from '../../../mock/roleTypes';
import { findUserAccessType } from '../../../helpers/findUserAccessType';
import { profileModel as model } from './model';
import { EngagementDashboardComponent } from '../../EngagementDashboard/EngagementDashboardComponent';
import { EngagementDashboardActions } from '../../../redux/engagementDashboard/EngagementDashboardActions';
import BrandOnboard from '../../../pages/BrandOnboard';

export interface IUserProfileProps {
	setProfileLayout: (value: boolean) => void;
	setIsOnFavoriteTab: (isOnFavoriteTab: boolean) => void;
	getEngagementDashboardData: (brandId: string) => void;
	engagementDashboardState: EngagementDashboardState | undefined;
}
const UserProfile: FC<IUserProfileProps> = ({
	setProfileLayout,
	setIsOnFavoriteTab,
	engagementDashboardState,
	getEngagementDashboardData
}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [mode, setMode] = useState<string>('read-mode');
	const [countriesList, setCountriesList] = useState<Array<CountryType>>([]);
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const [state, setState] = useState<UserProfileData>();
	const [toggleState, setToggleState] = useState(location.hash.replace('#', '') || '1');
	const [jobTypes, setJobTypes] = useState<string[]>([]);
	const favoritesPageRef = useRef<HTMLDivElement>(null);
	const isOnfavoriteTab = useSelector((state: RootState) => state.appState.isOnFavoritesPage);
	const navigate = useNavigate();
	const titleCallback = useCallback(
		() => dispatch(AppActions.setAppTitle('User profile')),
		[dispatch]
	);
	const favouriteCallback = useCallback(
		(flag: boolean) => dispatch(AppActions.setAppFavourite(flag)),
		[dispatch]
	);
	const toggleTab = (index: any) => {
		setToggleState(index);
		if (index === 3) {
			favouriteCallback(true);
		} else {
			favouriteCallback(false);
		}
		navigate(index === 1 ? '/profile' : `/profile#${index}`);
	};
	const getActiveClass = (index: any, className: string) => {
		return Number(toggleState) === index ? className : '';
	};
	const handleChange = (event: any) => {
		if (event.target.name === 'country') {
			setState({
				...state,
				country_name: countriesList.filter(item => item.id === event.target.value)[0].name,
				country: event.target.value
			});
		} else {
			setState({
				...state,
				[event.target.name]: event.target.value
			});
		}
	};
	const updateProfile = () => {
		dispatch(
			UserActions.updateUserProfile({
				companyDetails: {
					name: state?.company_name,
					website: state?.company_website,
					userType: state?.user_type,
					jobType: state?.job_type?.split(' ').join(''),
					isEditing: true
				},
				userProfile: {
					business_email: state?.business_email,
					business_type: state?.business_type,
					company: state?.company,
					country: state?.country,
					country_name: state?.country_name,
					first_name: state?.first_name,
					is_social_login: false,
					job_function: state?.job_function,
					id: state?.id,
					job_title: state?.job_title,
					last_name: state?.last_name,
					social_accounts: state?.social_accounts,
					state: state?.state,
					state_name: state?.state_name
				}
			})
		);
		setMode('read-mode');
	};
	useEffect(() => {
		if (userState) {
			setState(userState);
		}
	}, [userState]);

	useEffect(() => {
		setProfileLayout(true);
	}, []);

	useEffect(() => {
		if (location.hash !== '#target') {
			setToggleState(location.hash.replace('#', '') || '1');
		}
	}, [location.hash]);
	useEffect(() => {
		titleCallback();
		return () => {
			setProfileLayout(false);
		};
	}, []);
	useEffect(() => {
		if (window.IIRISProfileMgr) {
			window.IIRISProfileMgr.getTaxonomyData('countries?locale=en')?.then((data: any) => {
				setCountriesList(data);
			});
		}
	}, [window.IIRISProfileMgr]);
	useEffect(() => {
		if (state?.user_type) {
			setJobTypes(applicationJobTypesMap[state?.user_type]);
		}
	}, [state]);
	useEffect(() => {
		setIsOnFavoriteTab(
			(favoritesPageRef.current && favoritesPageRef.current.classList.contains('active-content')) ||
				false
		);
	}, [setIsOnFavoriteTab, toggleState]);
	useEffect(() => {
		setMode('read-mode');
		setState(userState);
	}, [toggleState]);

	return (
		<div
			data-testid='profile-container'
			className={`page-container ${
				toggleState === '2' || toggleState === '4' ? 'ingredient-background' : ''
			}`}
		>
			<div className='profile-detail'>
				<div className='icon'>
					<i className='ri-user-line'></i>
				</div>
				<div className='detail'>
					<div className='name'>
						{userState?.first_name} {userState?.last_name}
					</div>
					<div className='text'>{userState?.job_type}</div>
					<div className='text'>
						{userState?.state_name} {userState?.country_name}
					</div>
				</div>
			</div>
			<div
				className={`tabs-container ${
					toggleState === '2' || toggleState === '4' ? 'ingredient-background' : ''
				}`}
			>
				<ul className='tab-list'>
					<li className={`tabs ${getActiveClass(5, 'active-tabs')}`} onClick={() => toggleTab(5)}>
						<i className={toggleState === '5' ? 'ri-pages-fill' : 'ri-pages-line'}></i>
						{model.brandOnboardTabTitle}
					</li>
					<li
						data-testid='first-profile-tab'
						className={`tabs ${getActiveClass(1, 'active-tabs')}`}
						onClick={() => toggleTab(1)}
					>
						<i
							className={
								toggleState === '1' ? 'ri-folder-settings-fill' : 'ri-folder-settings-line'
							}
						></i>
						{model.profileTabTitle}
					</li>
					{findUserAccessType(state?.user_type) !== UserType.Brand && (
						<li
							data-testid='second-profile-tab'
							className={`tabs ${getActiveClass(2, 'active-tabs')}`}
							onClick={() => toggleTab(2)}
						>
							<i
								className={toggleState === '2' ? 'ri-file-list-2-fill' : 'ri-file-list-2-line'}
							></i>{' '}
							{model.ingredientsTabTitle}
						</li>
					)}
					{process.env.REACT_APP_SHOW_ENGAGEMENT_DASHBOARD === 'true' && (
						<>
							{(state?.user_type === JobTypeKeys.Exhibitor ||
								state?.user_type === JobTypeKeys.BeaconOnlyBrand) && (
								<li
									data-testid='charts-tab'
									className={`tabs ${getActiveClass(4, 'active-tabs')}`}
									onClick={() => toggleTab(4)}
								>
									<i id='profile-chart-icon' className='ri-pie-chart-2-fill'></i>{' '}
								</li>
							)}
						</>
					)}
					<li
						data-testid='third-profile-tab'
						className={`tabs ${getActiveClass(3, 'active-tabs')}`}
						onClick={() => toggleTab(3)}
					>
						<i className={!isOnfavoriteTab ? 'ri-heart-line' : 'ri-heart-fill'}></i>{' '}
						{model.favoritesTabTitle}
					</li>
				</ul>
				<div className='content-container'>
					<div className={`content ${getActiveClass(1, 'active-content')}`}>
						<div className='profile-container'>
							{mode === 'read-mode' && (
								<>
									<div className='card read-mode'>
										<div className='card-body'>
											<div
												data-testid='edit-profile-btn'
												className='edit-btn'
												onClick={() => {
													setMode('edit-mode');
												}}
											>
												<i className='ri-settings-3-fill gear-icon'></i>
												Edit Details
											</div>
											<div className='card-title'>Account Details</div>
											<div className='row-old'>
												<div className='col-12 col-sm-4 col-md-4 col-lg-4'>
													<div>
														<label htmlFor=''>First Name</label>
														<p>{state?.first_name}</p>
													</div>
													<div>
														<label htmlFor=''>Last Name</label>
														<p>{state?.last_name}</p>
													</div>
													<div>
														<label htmlFor=''>Email</label>
														<p>{state?.business_email}</p>
													</div>
												</div>
												<div className='col-12 col-sm-4 col-md-4 col-lg-4'>
													<div>
														<label htmlFor=''>Country</label>
														<p>{state?.country_name}</p>
													</div>
													<div>
														<label htmlFor=''>State/Province</label>
														<p>{state?.state_name}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='card read-mode'>
										<div className='card-body'>
											<div className='card-title'>Company Details</div>
											<div className='row-old'>
												<div className='col-12 col-sm-4 col-md-4 col-lg-4'>
													<div>
														<label htmlFor=''>Company</label>
														<p>{state?.company_name}</p>
													</div>
													<div>
														<label htmlFor=''>Company Website</label>
														<p>{state?.company_website}</p>
													</div>
												</div>
												<div className='col-12 col-sm-4 col-md-4 col-lg-4'>
													<div>
														<label htmlFor=''>I Am A...</label>
														<p>{state?.user_type}</p>
													</div>
													<div>
														<label htmlFor=''>Job Type</label>
														<p>{state?.job_type}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							)}
							{mode === 'edit-mode' && (
								<>
									<div data-testid='edit-mode' className='card edit-mode'>
										<div className='card-body'>
											<div className='card-title'>Account Details</div>
											<div className='row-old'>
												<div className='col-12 col-sm-4 col-md-4 col-lg-4'>
													<div>
														<label htmlFor=''>First Name</label>
														<div>
															<input
																type='text'
																name='first_name'
																className='form-control'
																value={state?.first_name}
																onChange={handleChange}
															/>
														</div>
													</div>
													<div>
														<label htmlFor=''>Last Name</label>
														<div>
															<input
																type='text'
																name='last_name'
																className='form-control'
																value={state?.last_name}
																onChange={handleChange}
															/>
														</div>
													</div>
													<div>
														<label htmlFor=''>Email</label>
														<div>
															<input
																type='text'
																className='form-control'
																name='business_email'
																value={state?.business_email}
																disabled
															/>
														</div>
													</div>
												</div>
												<div className='col-4 col-12 col-sm-4 col-md-4 col-lg-4'>
													<div>
														<label htmlFor=''>Country</label>
														<div>
															<select
																name='country'
																className='form-control'
																id=''
																onChange={handleChange}
																value={state?.country}
															>
																{countriesList.length !== 0 &&
																	countriesList.map((item, index) => (
																		<option key={`${item.id}-${index}`} value={item.id}>
																			{item.name}
																		</option>
																	))}
															</select>
														</div>
													</div>
													<div>
														<label htmlFor=''>State/Province</label>
														<div>
															<select name='state' className='form-control' id=''>
																<option key={state?.state}> {state?.state_name} </option>
															</select>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='card edit-mode'>
										<div className='card-body'>
											<div className='card-title'>Company Details</div>
											<div className='row-old'>
												<div className='col-12 col-sm-4 col-md-4 col-lg-4'>
													<div>
														<label htmlFor=''>Company</label>
														<div>
															<input
																type='text'
																name='company_name'
																className='form-control'
																value={state?.company_name ?? ''}
																onChange={handleChange}
															/>
														</div>
													</div>
													<div>
														<label htmlFor=''>Company Website</label>
														<div>
															<input
																type='text'
																name='company_website'
																className='form-control'
																value={state?.company_website ?? ''}
																onChange={handleChange}
															/>
														</div>
													</div>
												</div>
												<div className='col-12 col-sm-4 col-md-4 col-lg-4'>
													<div>
														<label htmlFor=''>I Am A...</label>
														<div>
															<select
																name='user_type'
																className='form-control hide-select-icon'
																id=''
																onChange={handleChange}
																value={state?.user_type ?? ''}
																disabled
															>
																<option disabled value=''>
																	Select
																</option>
																{applicationRoleTypes.map(userType => (
																	<option key={userType.id} value={userType.name}>
																		{userType.name}
																	</option>
																))}
															</select>
														</div>
													</div>
													<div>
														<label htmlFor=''>Job Type</label>
														<div>
															<select
																name='job_type'
																className='form-control'
																id=''
																value={state?.job_type ?? ''}
																onChange={handleChange}
																disabled={jobTypes.length === 0}
															>
																<option disabled value=''>
																	Select
																</option>
																{jobTypes.map((jobType: string, index: number) => (
																	<option key={index} value={jobType}>
																		{jobType}
																	</option>
																))}
															</select>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<button
										data-testid='save-changes-btn'
										className='save-btn'
										onClick={updateProfile}
									>
										Save Changes
									</button>
								</>
							)}
						</div>
					</div>
					{toggleState === '2' && (
						<div className={`content ${getActiveClass(2, 'active-content')}`}>
							<ProfileIngredientComponent toggleState={toggleState} />
						</div>
					)}
					<div ref={favoritesPageRef} className={`content ${getActiveClass(3, 'active-content')}`}>
						<FavoritesPage isProfilePage={true} />
					</div>
					{process.env.REACT_APP_SHOW_ENGAGEMENT_DASHBOARD === 'true' && (
						<div className={`content ${getActiveClass(4, 'active-content')}`}>
							<EngagementDashboardComponent
								engagementDashboardState={engagementDashboardState}
								brandId=''
								getEngagementData={getEngagementDashboardData}
								toggleState={toggleState}
							/>
						</div>
					)}
					<div className={`content ${getActiveClass(5, 'active-content')}`}>
						<BrandOnboard toggleState={toggleState} />
					</div>
				</div>
			</div>
			<div className='wave'></div>
		</div>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	engagementDashboardState: state?.dashboardState
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setProfileLayout: (isProfileLayout: boolean) => {
		dispatch(AppActions.setProfileLayout(isProfileLayout));
	},
	setIsOnFavoriteTab: (isOnFavoriteTab: boolean) => {
		dispatch(AppActions.isOnFavoritesPage(isOnFavoriteTab));
	},
	getEngagementDashboardData: (brandId: string) => {
		dispatch(EngagementDashboardActions.startFetchingEngagementData(brandId));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
