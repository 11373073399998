import './BrandFormComponent.scss';
import { useEffect, useState } from 'react';
import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import { BrandInfo, RootState } from '../../../../redux/AppReducer';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenType } from '../../../../common/view-model';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
interface IBrandForm {
	brandState: any;
	onUpdateProfileBrand: (userState: any) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	handleChange: (event: any) => void;
	onCancel: () => void;
}
const LARGE_MOBILE = 744;
const SMALL_MOBILE = 500;
const BrandFormComponent: React.FC<IBrandForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing,
	handleChange
}) => {
	const [state, setState] = useState<BrandInfo>();
	const ID = 'brand-details';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const [storyExpanded, setStoryExpanded] = useState<boolean>(false);
	const [missionExpanded, setMissionExpanded] = useState<boolean>(false);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const windowsSize = useWindowSize().width;
	const [charactersShown, setCharactersShown] = useState<number>(0);

	const handleToggle = (type: string) => {
		if (type === 'story') {
			setStoryExpanded(!storyExpanded);
		} else {
			setMissionExpanded(!missionExpanded);
		}
	};
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (brandState) {
			setState(brandState);
		}
	}, [brandState]);
	useEffect(() => {
		if (deviceScreen === ScreenType.LARGE_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.MEDIUM_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.SMALL_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.TABLET) {
			setCharactersShown(25);
		} else if (LARGE_MOBILE < windowsSize && windowsSize > SMALL_MOBILE) {
			setCharactersShown(40);
		} else if (SMALL_MOBILE < windowsSize) {
			setCharactersShown(26);
		}
	}, [deviceScreen, windowsSize]);
	return (
		<div className='brand-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => {}}
				titleText='Brand'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
			></TitleForms>

			{isVisible &&
				(isEditing !== ID ? (
					<>
						<p className='description-form'>
							Some information will be seen by retailers via your brand profile page on Beacon
							Discovery. Please make sure that all information is accurate and up-to-date.
						</p>
						<ContainerForms
							FormComponent1={
								<>
									<div className='brand-logo-container'>
										<LabelForms text='Brand Logo'></LabelForms>

										<div className='brand-image'>
											<div className='image-container'>
												<img src={state?.url_logo} alt='brand' />
											</div>
										</div>
									</div>
									<div>
										<LabelForms text='Headquarters City Location'></LabelForms>
										<p>{state?.hq_city}</p>
									</div>
									<div>
										<LabelForms text='Headquarters State Location'></LabelForms>
										<p>{state?.hq_state}</p>
									</div>
									<div>
										<LabelForms text='Headquarters Country Location'></LabelForms>
										<p>{state?.hq_country}</p>
									</div>
									<div>
										<LabelForms text='Year Founded'></LabelForms>
										<p>{state?.brand_foundation_year}</p>
									</div>
									<div className='mission-container'>
										<LabelForms text='Brand Mission'></LabelForms>
										<div>
											<p>
												{missionExpanded
													? state?.brand_mission
													: `${state?.brand_mission?.substring(0, charactersShown)}...`}
											</p>
											<button onClick={() => handleToggle('mission')}>
												{missionExpanded ? '' : 'Expand'}
											</button>
										</div>
									</div>
									<div className='story-container'>
										<LabelForms text='Brand Story'></LabelForms>
										<div>
											<p>
												{storyExpanded
													? state?.brand_story
													: `${state?.brand_story?.substring(0, charactersShown)}...`}
											</p>
											<button onClick={() => handleToggle('story')}>
												{storyExpanded ? '' : 'Expand'}
											</button>
										</div>
									</div>
								</>
							}
							FormComponent2={
								<>
									<div className='blank-space' />
									<div>
										<LabelForms text='Do you Offer Private Label Services?'></LabelForms>

										<ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-line' />}
											disabled
										/>
									</div>
									<div>
										<LabelForms text='Brand Video'></LabelForms>
										<p>{state?.brand_facebook}</p>
									</div>
									<div>
										<LabelForms text='Are you Owned by a Holding Company?'></LabelForms>
										<ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-fill' />}
											disabled
										/>
									</div>
									<div>
										<LabelForms text='Holding Company Name'></LabelForms>
										<p>{state?.holding_company}</p>
									</div>
									<div>
										<LabelForms text='Are you Owned by a Parent Company?'></LabelForms>
										<ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-fill' />}
											disabled
										/>
									</div>
									<div>
										<LabelForms text='Parent Company Name'></LabelForms>
										<p>{state?.brand_country}</p>
									</div>
									<div>
										<LabelForms text='What are your Current Service Provider Needs?'></LabelForms>
										{/* Change all the checkbox for a component, update the checkbox component to handle the logic*/}
										<div className='checkbox-container'>
											<i className='ri-checkbox-fill' />
											<p>Seek Broker / Wholesaler</p>
										</div>
									</div>
									<div>
										<LabelForms text='Are any of your Products Made Using these Processing Techniques?'></LabelForms>

										<div className='checkbox-container'>
											<i className='ri-checkbox-fill' />
											<p>
												Hydrogenotrophy – uses hydrogenotrophic microbes fermented with a mix of
												carbon dioxide, oxygen, minerals, water, and nitrogen to produce complex
												proteins or oils
											</p>
										</div>
									</div>
								</>
							}
						></ContainerForms>
					</>
				) : (
					<>
						<ActionsForms
							onUpdate={() => onUpdateProfileBrand(state)}
							onCancel={onCancel}
							toggleEditing={() => toggleEditing(null)}
						></ActionsForms>
					</>
				))}
		</div>
	);
};

export default React.memo(BrandFormComponent);
