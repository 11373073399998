import { useEffect } from 'react';
import { UserState } from '../redux/AppReducer';
import { validateAnonymousPath } from '../helpers/authenticationHelper';
import { eventPublicPathName } from '../common/constants';
import { getCacheUrl, removeCacheUrl, setCacheUrl } from '../helpers/cacheUrlAnonymousHelper';

export const useUrlValidationAnonymous = (userState?: UserState) => {
	useEffect(() => {
		if (!validateAnonymousPath(eventPublicPathName)) {
			removeCacheUrl();
		}
		if (
			validateAnonymousPath(eventPublicPathName) &&
			!userState?.hasLoadAnonymous &&
			window.location.pathname !== `/${eventPublicPathName}/anonymous` &&
			window.location.pathname !== '/' &&
			window.location.pathname !== '/landing'
		) {
			window.location.href = `/${eventPublicPathName}/anonymous`;
		}
	}, [userState?.hasLoadAnonymous]);

	useEffect(() => {
		const pathName = window.location.pathname;
		if (
			pathName !== `/${eventPublicPathName}` &&
			pathName !== `/${eventPublicPathName}/anonymous` &&
			validateAnonymousPath(eventPublicPathName)
		) {
			if (!getCacheUrl()) {
				setCacheUrl(pathName);
			}
		}
		if (
			validateAnonymousPath(eventPublicPathName) &&
			userState?.hasLoadAnonymous &&
			window.location.pathname === `/${eventPublicPathName}/anonymous`
		) {
			const redirectPath = getCacheUrl();
			if (redirectPath) {
				window.location.href = redirectPath;
			} else {
				window.location.href = `/${eventPublicPathName}`;
			}
		}
	}, [userState?.hasLoadAnonymous]);
};
