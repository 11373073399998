import './index.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';
import { IirisAuthenticationComponent } from '../../authentication/iirisAuthenticationComponent';
import beaconLogo from '../../../assets/images/Horizontal.svg';
import { ScreenType, TrackingEventStatus, TrackingEventType } from '../../../common/view-model';
import { v4 as uuidv4 } from 'uuid';
import { handleTracking } from '../../../helpers/handelTracking';
import { SearchBarComponent } from '../../UI/SearchBarComponent/SearchBarComponent';
import { useShowSearch } from '../../UI/SearchBarComponent/hooks/useShowSearch';
import { useShowMobileSearch } from '../../UI/SearchBarComponent/hooks/useShowMobileSearch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchActions } from '../../../redux/search/SearchActions';
import { SearchPredictionComponent } from '../../UI/SearchPredictionComponent/SearchPredictionComponent';
import { useSearchInputChange } from '../../UI/SearchBarComponent/hooks/useSearchInputChange';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { FilterActions } from '../../../redux/FilterActions';
import { AppActions } from '../../../redux/AppActions';
import { eventPublicPathName, publicProductDiscoveryActions } from '../../../common/constants';
import {
	isCurrentUserAnonymous,
	validateAnonymousPath
} from '../../../helpers/authenticationHelper';
import { searchTermIndentifier } from '../../../helpers/paramsUrlIndetifiers';
import { UserActions } from '../../../redux/UserActions';
export default function Navbar() {
	const pagesNotUsingSearch = useMemo(() => ['/profile'], []);
	const { width } = useWindowSize();
	const mobileWidthBreakpoint = 743;
	const navigate = useNavigate();
	const location = useLocation();
	const mainPageUrl = '/products';
	const dispatch = useDispatch();
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const filterState = useSelector((state: RootState) => state.appState.filterState);
	const currentEvent = useSelector(
		(state: RootState) => state.appState.storeState?.getCurrentEvent
	);
	const isOnFavoritesPage = useSelector((state: RootState) => state.appState.isOnFavoritesPage);
	const { deviceScreen } = useSelector((state: RootState) => state.appState);
	const { showSearch } = useShowSearch(width);
	const showFavoritesTextTablet = deviceScreen !== ScreenType.TABLET && showSearch;
	const { showMobileSearch, setOpen } = useShowMobileSearch(width, deviceScreen);
	const { searchState } = useSelector((state: RootState) => state.appState);
	const { errorState } = useSelector((state: RootState) => state.appState);
	const [showLoadingSearch, setShowLoadingSearch] = useState(false);
	const showExpoText =
		deviceScreen !== ScreenType.TABLET && deviceScreen !== ScreenType.SMALL_DESKTOP;
	const handleClick = () => {
		handleTracking({
			transactionId: uuidv4(),
			status: TrackingEventStatus.SUCCESS,
			entityId: currentEvent?.id!,
			type: TrackingEventType.CLICK_ON_EXPO_MAP,
			timeStamp: new Date().toISOString(),
			eventPayload: {
				id: currentEvent?.id!,
				name: currentEvent?.name!,
				type: currentEvent?.name!,
				url: window.location.href,
				tags: [],
				metadata: {
					user_id: userState?.id,
					user_type: userState?.user_type,
					environment: process.env.REACT_APP_ENV_NAME,
					event_id: currentEvent?.id,
					event_name: currentEvent?.name
				}
			}
		});
	};
	const handleHover = () => {
		if (filterState?.onMouseLeaveCategoryNavbar) {
			filterState?.onMouseLeaveCategoryNavbar();
		}
	};
	useEffect(() => {
		if (searchState?.hasClickedOutsideBar) {
			setOpen(false);
			dispatch(SearchActions.seHasClickedOutsideSearch(false));
			setShowLoadingSearch(false);
		}
	}, [dispatch, searchState?.hasClickedOutsideBar, setOpen]);
	const starSuggestionsCallback = useCallback(
		(term: string) => {
			if (term.trimStart()) {
				dispatch(SearchActions.startSuggestionSearch(term));
				setShowLoadingSearch(true);
			} else {
				dispatch(SearchActions.clearSearchSuggestions());
				setShowLoadingSearch(false);
			}
		},
		[dispatch]
	);
	const [showPrediction, setShowPrediction] = useState(true);
	const { value, handleChange, reset, setValue } = useSearchInputChange(
		'',
		starSuggestionsCallback,
		setShowPrediction,
		searchState?.searchTerm
	);
	const setSearchTermCallback = useCallback(
		(term?: string, type?: string) => {
			dispatch(AppActions.setGeneralError({ hasError: false }));
			dispatch(SearchActions.setSearchTerm(term ? { name: term, type: type || '' } : undefined));
			setValue(term || '');
			setShowPrediction(false);
			if (term) {
				handleTracking({
					transactionId: uuidv4(),
					status: TrackingEventStatus.SUCCESS,
					entityId: term,
					type: TrackingEventType.SEARCH_TERM,
					timeStamp: new Date().toISOString(),
					eventPayload: {
						id: term,
						name: term,
						type: type || 'N/A',
						url: window.location.href,
						tags: [],
						metadata: {
							user_type: userState?.user_type,
							user_id: userState?.id,
							environment: process.env.REACT_APP_ENV_NAME,
							search_term: term
						}
					}
				});
				if (isCurrentUserAnonymous()) {
					dispatch(UserActions.registerUserClickEvent(publicProductDiscoveryActions.SEARCH_MADE));
				}
			}
			if (location.pathname !== mainPageUrl) {
				navigate(
					!isCurrentUserAnonymous()
						? mainPageUrl
						: `.${mainPageUrl}${
								term
									? `?${searchTermIndentifier}=${JSON.stringify({ name: term, type: type || '' })}`
									: ''
						  }`
				);
			}
		},
		[dispatch, location.pathname, navigate, setValue, userState]
	);
	const setValueCallback = useCallback(
		(term: string) => {
			setValue(term);
			setShowPrediction(false);
		},
		[setValue]
	);
	useEffect(() => {
		setShowLoadingSearch(false);
	}, [searchState?.suggestions]);
	const clearAllCallback = useCallback(() => {
		if (errorState?.hasError) {
			dispatch(AppActions.setGeneralError({ hasError: false }));
			navigate(validateAnonymousPath(eventPublicPathName) ? `/${eventPublicPathName}` : '/');
		}
		dispatch(FilterActions.clearAllFiltersAndSearchTerm());
		dispatch(AppActions.setUserReturns(false));
		sessionStorage.removeItem('scrollPosition');
	}, [dispatch, navigate, errorState]);

	const handleLinkClick = (path: string) => {
		dispatch(AppActions.setGeneralError({ hasError: false }));
		navigate(path);
	};

	return (
		<nav
			data-testid='navbar'
			className={`nav-container ${
				validateAnonymousPath(eventPublicPathName) ? 'anonymous-content' : ''
			}`}
			onMouseOver={handleHover}
		>
			{!showMobileSearch && (
				<div className='bacon-icon' onClick={clearAllCallback}>
					<Link
						className='product-link'
						to={validateAnonymousPath(eventPublicPathName) ? `/${eventPublicPathName}` : '/home'}
					>
						<img src={beaconLogo} alt='bacon-icon' />
					</Link>
				</div>
			)}
			{((showSearch && deviceScreen !== ScreenType.MOBILE) || showMobileSearch) && (
				<div
					className={`search-container ${
						validateAnonymousPath(eventPublicPathName) ? 'search-anonymous-wrapper' : ''
					}`}
				>
					<SearchBarComponent
						handleSearch={() => setSearchTermCallback(value)}
						handleClose={
							deviceScreen === ScreenType.MOBILE || width <= mobileWidthBreakpoint
								? () => setOpen(false)
								: undefined
						}
						placeholder={
							deviceScreen === ScreenType.MOBILE || width <= mobileWidthBreakpoint
								? 'Search for products and more'
								: ''
						}
						startSuggestionSearch={starSuggestionsCallback}
						value={value}
						reset={reset}
						handleChange={handleChange}
						handleClearSearch={() => setSearchTermCallback(undefined)}
					/>
					<SearchPredictionComponent
						suggestions={searchState?.suggestions || []}
						onSuggestionSelected={setSearchTermCallback}
						value={value}
						setValueCallback={setValueCallback}
						showPrediction={showPrediction}
						loadingSearch={showLoadingSearch}
					/>
				</div>
			)}
			{!showMobileSearch && (
				<div
					className={`btn-group ${
						currentEvent && !validateAnonymousPath(eventPublicPathName) ? 'event-wrapper' : ''
					}`}
				>
					{!validateAnonymousPath(eventPublicPathName) && (
						<>
							{currentEvent &&
								!(deviceScreen === ScreenType.MOBILE || width <= mobileWidthBreakpoint) && (
									<a
										className={`showfloor-map ${!showExpoText ? 'no-text-wrapper' : ''}`}
										target='blank'
										href={currentEvent.floorMapUrl}
										onClick={handleClick}
									>
										{currentEvent?.floorMapUrl && (
											<>
												<i className='ri-map-2-line'></i>
												{showExpoText && <span>Expo Show Floor Map</span>}
											</>
										)}
									</a>
								)}
							{!showMobileSearch && !pagesNotUsingSearch.includes(location.pathname) && (
								<i className='ri-search-line search-icon' onClick={() => setOpen(true)}></i>
							)}
							{!(deviceScreen === ScreenType.MOBILE || width <= mobileWidthBreakpoint) && (
								<Link to='../profile#3' onClick={() => handleLinkClick('../profile#3')}>
									<div
										className={`favorites ${
											!showFavoritesTextTablet ? 'favorites-text-hidden ' : ''
										}${!currentEvent ? 'no-event' : ''}`}
									>
										<i className={isOnFavoritesPage ? 'ri-heart-fill' : 'ri-heart-line'}></i>
										{showFavoritesTextTablet && <span>Favorites</span>}
									</div>
								</Link>
							)}
						</>
					)}

					<div className='user-auth'>
						<IirisAuthenticationComponent
							mobileView={deviceScreen === ScreenType.MOBILE || width <= mobileWidthBreakpoint}
							eventLink={currentEvent?.floorMapUrl}
						/>
					</div>
				</div>
			)}
		</nav>
	);
}
