import { newHopeAudienceEmail } from '../../../common/constants';

const DEFAULT_STATE: INeedToMakeChangesState = {
	title: 'Fast track your updates!',
	headerPrevText: 'New Hope Network will complete any updates on your brand’s behalf, just email',
	headerNextText: 'and say “I want New Hope to upload product data for me.”',
	headerEmailLink: newHopeAudienceEmail,
	firstCardHeaderPrevText: 'Update product data and images in the Product Portal',
	firstCardHeaderTextLink: 'here',
	firstCardHeaderLink: 'https://app.pinto.co/catalog',
	firstCardContentText:
		'Upload new products, delete existing ones, or update product information that has changed since you last exhibited. Skip this step with the Fast track option above!',
	secondCardHeaderText: 'See your products on Beacon Discovery',
	secondCardContentText:
		'Once approved by our Standards team, your brand can be discovered by retailers on Beacon immediately!'
};

interface INeedToMakeChangesState {
	title: string;
	headerPrevText: string;
	headerNextText: string;
	headerEmailLink: string;

	firstCardHeaderPrevText: string;
	firstCardHeaderTextLink: string;
	firstCardHeaderLink: string;
	firstCardContentText: string;

	secondCardHeaderText: string;
	secondCardContentText: string;
}
export const NeedToMakeChangesModel = {
	...DEFAULT_STATE
};
