import { newHopeAudienceEmail } from '../../../common/constants';

const DEFAULT_STATE: IBeaconOnlyBrandState = {
	title: 'Fast track your onboarding!',
	headerEmailLink: newHopeAudienceEmail,
	headerPrevText:
		'New Hope Network will complete Step 3 of this process on your brand’s behalf if you give consent in the Standards form in Step 1 or email',
	headerNextText: 'and say “I want New Hope to upload product data for me.”',
	firstCardHeaderText: 'Complete the Standards form',
	firstCardHeaderTextLink: 'here',
	firstCardHeaderLink: 'https://standards.newhope.com/beacon-only/',
	firstCardContentText: 'Check ‘Yes’ on the consent page to fast track your onboarding.',

	thirdCardPrevText:
		'Create an account for New Hope Network’s Product Portal offered by Pinto/SPINS',
	thirdCardLinkText: 'here',
	thirdCardContentText:
		'Once your account is created, you will receive your login credentials via email.',
	thirdCardLink: 'https://app.pinto.co/sign-up/request?partnerName=new-hope-network',
	fourthCardPrevText: 'Upload product data and images into Product Portal for review',
	fourthCardLinkText: 'here',
	fourthCardLink: 'https://survey.alchemer.com/s3/7555625/NHN-Consents-to-Onboard-with-SPINS-Pinto',
	fourthCardContentBoldText:
		'Fast track your onboarding and let us upload your product data for you!',
	fourthCardContentPrev: 'Give consent in the Standards form or email',
	fourthCardEmail: newHopeAudienceEmail,
	fourthCardContentPostText: 'and say “I want New Hope to upload product data for me.”',
	fiveCardHeaderText: 'See your products on Beacon Discovery',
	fiveCardContentText:
		'Once approved by our Standards team, your brand can be discovered by retailers on Beacon immediately!'
};

interface IBeaconOnlyBrandState {
	title: string;
	headerPrevText: string;
	headerNextText: string;
	headerEmailLink: string;

	firstCardHeaderText: string;
	firstCardHeaderTextLink: string;
	firstCardHeaderLink: string;
	firstCardContentText: string;

	thirdCardPrevText: string;
	thirdCardLinkText: string;
	thirdCardLink: string;
	thirdCardContentText: string;

	fourthCardPrevText: string;
	fourthCardLinkText: string;
	fourthCardLink: string;
	fourthCardContentPrev: string;
	fourthCardContentPostText: string;
	fourthCardContentBoldText: string;
	fourthCardEmail: string;

	fiveCardHeaderText: string;
	fiveCardContentText: string;
}

export const BeaconOnlyBrandModel = {
	...DEFAULT_STATE
};
