import React from 'react';
import { BrandInfo } from '../../../../redux/AppReducer';
import { ButtonComponent } from '../../../UI/ButtonComponent/ButtonComponent';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import './ContactFormComponent.scss';
import { useEffect, useState } from 'react';

interface IContactForm {
	brandState: any;
	onUpdateProfileBrand: (userState: any) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	handleChange: (event: any) => void;
	onCancel: () => void;
}
const ContactFormComponent: React.FC<IContactForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing,
	handleChange
}) => {
	const [state, setState] = useState<BrandInfo>();
	const ID = 'contact-form';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (brandState) {
			setState(brandState);
		}
	}, [brandState]);

	return (
		<div className='contact-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => onUpdateProfileBrand(state)}
				titleText='Contact Information for Retailers'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
			></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms text='Brand Rep Name'></LabelForms>

										<p>{state?.contact_name}</p>
									</div>
									<div>
										<LabelForms text='Brand Rep Email'></LabelForms>

										<p>{state?.contact_email}</p>
									</div>
									<div>
										<LabelForms text='Brand Rep Phone Number'></LabelForms>
										<p>{state?.contact_phone}</p>
									</div>
									<div>
										<LabelForms text='Brand Rep Job Title'></LabelForms>
										<p>{state?.contact_title}</p>
									</div>
									<div>
										<LabelForms text='Product Rep Name'></LabelForms>
										<p>{state?.name}</p>
									</div>
									<div>
										<LabelForms text='Product Rep Email'></LabelForms>
										<p>{state?.contact_name}</p>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Do you Have a Broker?'></LabelForms>

										<ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-fill' />}
											disabled
										/>
									</div>
									<div>
										<LabelForms text='Broker 1 Details'></LabelForms>

										<p>{state?.name}</p>
									</div>
									<div>
										<LabelForms text='Broker 2 Details'></LabelForms>
										<p className={state?.boothNumber ? '' : 'blank-field'}>{state?.boothNumber}</p>
									</div>
									<div>
										<LabelForms text='Broker 3 Details'></LabelForms>
										<p className={state?.boothNumber ? '' : 'blank-field'}>{state?.boothNumber}</p>
									</div>
									<div>
										<LabelForms text='Product Rep Phone Number'></LabelForms>
										<p>{state?.contact_name}</p>
									</div>
									<div>
										<LabelForms text='Product Rep Job Title'></LabelForms>
										<p>{state?.contact_title}</p>
									</div>
								</>
							}
						></ContainerForms>
					</>
				) : (
					<>
						<ActionsForms
							onUpdate={() => onUpdateProfileBrand(state)}
							onCancel={onCancel}
							toggleEditing={() => toggleEditing(null)}
						></ActionsForms>
					</>
				))}
		</div>
	);
};

export default React.memo(ContactFormComponent);
