import { useEffect, useState } from 'react';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import { Product } from '../../../../../../../common/model';
import React from 'react';
import './ProductStandardsFormComponent.scss';
interface IProductStandardsForm {
	productState: any;
}
const ProductStandardsFormComponent: React.FC<IProductStandardsForm> = ({ productState }) => {
	const [state, setState] = useState<Product>();
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (productState) {
			setState(productState);
		}
	}, [productState]);
	return (
		<div className='product-standards-form'>
			<TitleForms
				isVisible={isVisible}
				titleText='Standards Team Suggestions'
				toggleVisibility={toggleVisibility}
				readOnly
			></TitleForms>

			{isVisible && (
				<>
					<ContainerForms
						FormComponent1={
							<>
								{' '}
								<div>
									<LabelForms
										text='Allergen Warning Missing
											'
									></LabelForms>
									<p className='info-text'>
										To provide this information, please access the NHN Product Portal here.
									</p>
								</div>
							</>
						}
					></ContainerForms>
				</>
			)}
		</div>
	);
};

export default React.memo(ProductStandardsFormComponent);
