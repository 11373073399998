import { Action } from 'redux-actions';
import { ofType } from 'redux-observable';
import { from, map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AppState } from './AppReducer';
import { AuthActions } from './AuthActions';
import { removeAllAuthenticationKeysFromLocalStorage } from '../helpers/authenticationHelper';
import { UserActions } from './UserActions';

export class AuthEpics {
	static init() {
		return [AuthEpics.logoutEpic, AuthEpics.closeModalCompanyDetailsEpic];
	}

	static logoutEpic = (action$: Observable<Action<AppState>>): Observable<any> => {
		return action$.pipe(
			ofType(AuthActions.LOGOUT),
			map((action: Action<AppState>) => {
				const iirisLogoutButtonId = 'irisLogoutBtn';
				document.getElementById(iirisLogoutButtonId)?.click();
				removeAllAuthenticationKeysFromLocalStorage();
				return from([
					{ type: 'END_STATE', payload: action.payload },
					UserActions.updaUserBeaconInfoStatus(undefined)
				]);
			})
		);
	};
	static closeModalCompanyDetailsEpic = (
		action$: Observable<Action<AppState>>
	): Observable<Action<any>> => {
		return action$.pipe(
			ofType(AuthActions.CLOSE_MODAL_COMPANY_DETAILS),
			map((action: Action<AppState>) => {
				removeAllAuthenticationKeysFromLocalStorage();
				return AuthActions.onModalCompanyDetailsClosed();
			})
		);
	};
}
