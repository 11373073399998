import { useEffect } from 'react';
export const useWidgetLoader = () => {
	useEffect(() => {
		let identityScript = document.createElement('script');
		identityScript.type = 'text/javascript';
		identityScript.async = true;
		identityScript.src = process.env.REACT_APP_IDENTITY_SCRIPT_SRC!;

		let profileScript = document.createElement('script');
		profileScript.type = 'text/javascript';
		profileScript.async = true;
		profileScript.src = process.env.REACT_APP_PROFILE_SCRIPT_SRC!;

		document.getElementsByTagName('head')[0].appendChild(identityScript);
		document.getElementsByTagName('head')[0].appendChild(profileScript);

		return () => {
			const existingIdentityScript = document.querySelector(`[src="${identityScript.src}"]`);
			if (existingIdentityScript) {
				existingIdentityScript.parentNode?.removeChild(existingIdentityScript);
			}

			const existingProfileScript = document.querySelector(`[src="${profileScript.src}"]`);
			if (existingProfileScript) {
				existingProfileScript.parentNode?.removeChild(existingProfileScript);
			}
		};
	}, []);
};
