import { ErrorState, RootState } from '../../redux/AppReducer';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
	BrandVM,
	FilterVM,
	FilterType,
	sortOptionType,
	ScreenType,
	TrackingEventStatus,
	TrackingEventType
} from '../../common/view-model';
import { Events, Product } from '../../common/model';
import { v4 as uuidv4 } from 'uuid';
import { FilterActions } from '../../redux/FilterActions';
import { handleTracking } from '../../helpers/handelTracking';
import './BrandProfileComponent.scss';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { aboutBrandState } from './model';
import BrandAttributesComponent from './brand-attributes/BrandAttributesComponent';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PRODUCTS } from '../../graphql/queries/products';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Swiper as ISwiper, Grid } from 'swiper';
import { BoothNumberComponent } from '../UI/BoothNumberComponent/BoothNumberComponent';
import ProductComponent from '../product/ProductComponent';

import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { ExpoLabelComponent } from '../UI/ExpoLabelComponent/ExpoLabelComponent';
import { AppActions } from '../../redux/AppActions';
import { ExpoEastOverlay } from '../ExpoEastOverlay/ExpoEastOverlay';
import { GoBackButtonComponent } from '../UI/GoBackButtonComponent/GoBackButtonComponent';
import { useQuery } from '@apollo/client';
import { eventPublicPathName, publicBrandDetailActions, serverError } from '../../common/constants';
import { getFilterTypeKeyForUrl } from '../../helpers/urlParametersHelpers';
import { isCurrentUserAnonymous } from '../../helpers/authenticationHelper';
import LockOverlayComponent from '../LockOverlay/LockOverlayComponent';
import { UserActions } from '../../redux/UserActions';
import { ContactInformationComponent } from '../product-detail/accordion/contactInformation/ContactInformationComponent';

interface IBrandProfileComponentProps {
	brand: BrandVM;
	handleUnauthorized: () => void;
	handleError: (error: ErrorState) => void;
	currentEvent?: Events | null;
	setLockModalContent: () => void;
	registerUserClickEvent: (event: string) => void;
}
const BrandProfileComponent: FC<IBrandProfileComponentProps> = ({
	brand,
	handleError,
	handleUnauthorized,
	currentEvent,
	setLockModalContent,
	registerUserClickEvent
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const swiperRef = useRef<ISwiper>();

	const [state] = useState({ ...aboutBrandState });
	const { ingredientsTemplateId } = useSelector((state: RootState) => state.appState?.userState!);
	const userState = useSelector((state: RootState) => state.appState.userState?.user);

	const { favoriteProducts } = useSelector((state: RootState) => state.appState?.userState!);
	const { sortBySelected, sortByOptions } = useSelector(
		(state: RootState) => state.appState?.filterState!
	);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const [desktopSlidePerview, setDesktopSlidePerview] = useState(6);
	const { isLoadingData } = useSelector((state: RootState) => state.appState);
	const isLoadingCallback = useCallback(
		(loading: boolean) => dispatch(AppActions.setIsLoadingData(loading)),
		[dispatch]
	);
	const [products, setProducts] = useState<Product[]>([]);

	useEffect(() => {
		if (deviceScreen === ScreenType.LARGE_DESKTOP) {
			setDesktopSlidePerview(6);
		} else if (deviceScreen === ScreenType.MEDIUM_DESKTOP) {
			setDesktopSlidePerview(5);
		} else if (deviceScreen === ScreenType.SMALL_DESKTOP) {
			setDesktopSlidePerview(4);
		} else if (deviceScreen === ScreenType.TABLET) {
			setDesktopSlidePerview(3);
		}
	}, [deviceScreen]);

	const sortBy =
		sortBySelected?.sortType === sortOptionType.MOST_RELEVANT
			? sortByOptions?.find(x => x.sortType === sortOptionType.ALPHABETICAL)
			: sortBySelected;

	const { loading, data, error } = useQuery(GET_PRODUCTS, {
		variables: {
			genericFilters: [
				{
					key: 'Brand',
					ids: [brand.id]
				}
			],
			sortAttribute: sortBy?.productSort.attribute,
			sortDirection: sortBy?.productSort.order,
			templateId: String(ingredientsTemplateId),
			expoEventId: currentEvent?.id
		},
		skip: currentEvent === undefined
	});
	useEffect(() => {
		isLoadingCallback(true);
		if (!error && !loading && data?.products?.products) {
			setProducts(data?.products?.products);
			isLoadingCallback(false);
		}
		if (error?.message === 'Unauthorized') {
			handleUnauthorized();
			return;
		}
		if (error) {
			handleError(serverError);
		}
	}, [loading, data, error, isLoadingCallback, handleUnauthorized, handleError]);

	const filter: FilterVM = {
		id: brand.id,
		name: brand.name,
		children: undefined,
		isChecked: true,
		type: FilterType.BRAND
	};

	const handleViewProductsClk = () => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicBrandDetailActions.CLICK_SEE_ALL_PRODUCTS);
		}
		dispatch(FilterActions.removeAllAndAddFilter(filter));
		dispatch(AppActions.setProductsCached([]));
		dispatch(AppActions.setUserReturns(false));
		dispatch(AppActions.setPage(0));
		sessionStorage.setItem('scrollPosition', '' + 0);
		navigate(
			`${
				!isCurrentUserAnonymous() ? '/products?' : `/${eventPublicPathName}/products?`
			}${getFilterTypeKeyForUrl(FilterType.BRAND)}=${brand.id}`
		);
	};

	const openExpoPopUpCallback = useCallback(() => {
		dispatch(
			AppActions.setModalContent({
				content: <ExpoEastOverlay />,
				showCloseIcon: false,
				canScroll: true,
				closeOnDocumentClick: true
			})
		);

		handleTracking({
			transactionId: uuidv4(),
			entityId: brand?.id!,
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.CLICK_ON_SEE_US_EXPO_BUTTON,
			timeStamp: new Date().toISOString(),
			eventPayload: {
				id: currentEvent?.id!,
				name: currentEvent?.name!,
				type: brand?.name!,
				url: window.location.href,
				tags: [],
				metadata: {
					user_id: userState?.id,
					user_type: userState?.user_type,
					environment: process.env.REACT_APP_ENV_NAME,
					event_id: currentEvent?.id,
					event_name: currentEvent?.name
				}
			}
		});
	}, [dispatch]);
	const handleNavigateBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	return (
		<div data-testid='brand-profile-container' className='brand-profile-container'>
			<GoBackButtonComponent
				handelNavigate={handleNavigateBack}
				text={state.backToPageText}
				icon={<i className='fa fa-arrow-left' aria-hidden='true'></i>}
			/>
			<div className='brand-profile'>
				<div className='brand-profile-name'>
					<span className='brand-name'>{brand.name}</span>
				</div>
				<div className='brand-information'>
					<div className='brand-image'>
						<div className='image-container'>
							<img src={brand.url_logo} alt='brand' />
						</div>
					</div>
					<div className='brand-attributes-container'>
						{brand.boothNumber && currentEvent && currentEvent != null && (
							<>
								<BoothNumberComponent
									text={`Booth #${brand.boothNumber}`}
									boothStyle='primary'
									icon='ri-store-3-line'
								/>
								<ExpoLabelComponent
									text={`See us at ${currentEvent?.label || currentEvent.name}!`}
									labelStyle={`large ${currentEvent?.name
										.replace(/\s+/g, '')
										.toLocaleLowerCase()
										.trim()}`}
									icon=''
									showAnimation={true}
									onClick={openExpoPopUpCallback}
								/>
							</>
						)}
						<div className='brand-attributes'>
							<div className='attributes-container'>
								{brand.attributes.map((attribute, index) => {
									return (
										<BrandAttributesComponent
											key={index}
											{...attribute}
											registerUserClickEvent={registerUserClickEvent}
										/>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				<div className='brand-details'>
					<div className={`mission-container  no-card `}>
						<div className='mission-item'>
							<span className='brand-info-title'>{state.missionStatementTitleText}</span>
							<p className='brand-mission'>{brand.brand_mission}</p>
						</div>
						<div className='mission-item'>
							<span className='brand-info-title'>{state.ourStoryTitleText}</span>
							<p className='brand-story'>{brand.brand_story}</p>
						</div>
					</div>
				</div>
			</div>
			{!isLoadingData ? (
				<div className='brand-products'>
					<div>
						<Link
							to={`${
								!isCurrentUserAnonymous() ? '/products?' : `/${eventPublicPathName}/products?`
							}${getFilterTypeKeyForUrl(FilterType.BRAND)}=${brand.id}`}
							data-testid='go-to-brand-link'
							className='brand-products-link'
							onClick={handleViewProductsClk}
						>
							{`View All ${brand.name}'s Products`}
							<div>
								<i className='ri-arrow-right-line icon-arrow'></i>
							</div>
						</Link>
					</div>

					<div className='products-carousel-container'>
						{deviceScreen !== ScreenType.MOBILE ? (
							<>
								<Swiper
									data-testid='desktop-swiper'
									slidesPerView={desktopSlidePerview}
									spaceBetween={24}
									navigation={false}
									pagination={{
										clickable: true
									}}
									modules={[Pagination, Navigation]}
									onBeforeInit={swiper => {
										swiperRef.current = swiper;
									}}
								>
									{products.map((product: Product, index: number) => (
										<SwiperSlide key={index}>
											<ProductComponent
												product={product}
												favorite={favoriteProducts?.some(favorite => favorite.pid === product?.id)}
												isOnBrandPage={true}
												anonymousEventName={publicBrandDetailActions.CLICK_HEART_ICON}
											/>
										</SwiperSlide>
									))}
								</Swiper>
								{products.length > desktopSlidePerview && (
									<>
										<button
											data-testid='desktop-swiper-arrow-prev'
											className='swiper-button-prev'
											onClick={() => swiperRef.current?.slidePrev()}
										></button>
										<button
											data-testid='desktop-swiper-arrow-next'
											className='swiper-button-next'
											onClick={() => swiperRef.current?.slideNext()}
										></button>
									</>
								)}
							</>
						) : (
							<>
								{products.length > 0 && (
									<>
										<Swiper
											data-testid='mobile-swiper'
											observeParents={true}
											slidesPerView={2}
											spaceBetween={30}
											grid={{
												rows: 2,
												fill: 'row'
											}}
											navigation={true}
											pagination={{
												el: '.swiper-custom-pagination-for-brand',
												clickable: true,
												dynamicBullets: products.length > 20,
												dynamicMainBullets: 5
											}}
											modules={[Grid, Pagination]}
											onBeforeInit={swiper => {
												swiperRef.current = swiper;
											}}
										>
											{products.map((product: Product, index: number) => (
												<SwiperSlide key={index}>
													<ProductComponent
														product={product}
														favorite={favoriteProducts?.some(
															favorite => favorite.pid === product?.id
														)}
														isOnBrandPage={true}
														anonymousEventName={publicBrandDetailActions.CLICK_HEART_ICON}
													/>
												</SwiperSlide>
											))}
										</Swiper>
										{products.length > 4 && (
											<>
												<div data-testid='mobile-swiper-thumb'>
													<div
														className='swiper-custom-pagination-for-brand'
														style={{
															marginRight: products.length > 20 ? '0px' : 'auto',
															marginLeft: products.length > 20 ? '0px' : 'auto'
														}}
													></div>
												</div>

												<div
													data-testid='mobile-swiper-arrows'
													className='control-mobile-for-brand'
												>
													<i
														className='bi bi-chevron-left'
														onClick={() => {
															swiperRef.current?.slidePrev();
														}}
													></i>
													<i
														className='bi bi-chevron-right'
														onClick={() => {
															swiperRef.current?.slideNext();
														}}
													></i>
												</div>
											</>
										)}
									</>
								)}
							</>
						)}
					</div>
				</div>
			) : null}
			<div className={`contact-container ${products.length <= 2 ? 'short-products-wrapper' : ''}`}>
				<div className='brand-contact-title-container'>
					<span className='brand-contact-title'>{state.contactBrandTitle}</span>
				</div>
				<ContactInformationComponent
					brokers={brand?.brokers}
					contact={{
						name: brand?.contact_name,
						email: brand.contact_email,
						phone: brand.contact_phone
					}}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	currentEvent: state.storeState?.getCurrentEvent
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	handleUnauthorized: () => {
		return dispatch(AppActions.unauthorizedError());
	},
	handleError: (error: ErrorState) => {
		return dispatch(AppActions.setGeneralError(error));
	},
	setLockModalContent: () => {
		dispatch(
			AppActions.setModalContent({
				content: <LockOverlayComponent />,
				showCloseIcon: true,
				canScroll: false,
				closeOnDocumentClick: true
			})
		);
	},
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandProfileComponent);
