import './index.scss';
import { Dispatch, FC, useEffect } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { RootState, UserState } from '../../redux/AppReducer';
import { EventSliderComponent } from '../../components/EventSlider/EventSliderComponent';
import { WhatIsNewSectionComponent } from '../../components/WhatIsNewSection/WhatIsNewSectionComponent';
import { BrowseByCategoryComponent } from '../../components/BrowseByCategory/BrowseByCategoryComponent';
import { useNavigate } from 'react-router-dom';
import { AppActions } from '../../redux/AppActions';
import {
	FilterType,
	FilterVM,
	ITrackingEvent,
	TaxonomyVM,
	TrackingEmailVariables,
	TrackingEventStatus,
	TrackingEventType
} from '../../common/view-model';
import { FilterActions } from '../../redux/FilterActions';
import { UserActions } from '../../redux/UserActions';
import HighlightedProducts from '../../components/highlightedProducts/HighlightedProductsComponent';
import HighlightedBrands from '../../components/highlightedBrands/HighlightedBrandsComponent';
import { HomePageOnboardComponent } from '../../components/HomePageOnboard/HomePageOnboardComponent';
import {
	IirisIdentityKey,
	getLocalStorageValue,
	isCurrentUserAnonymous
} from '../../helpers/authenticationHelper';
import { eventPublicPathName } from '../../common/constants';
import { getUrlTrackingParamsObject } from '../../helpers/getUrlParamsObject';
import { handleTracking } from '../../helpers/handelTracking';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

interface HomePageProps {
	addFilter: (filter: FilterVM) => void;
	hasMappedAllFilters?: boolean;
	registerUserClickEvent: (event: string) => void;
	setIsOnHomePage: (isOnHomePage: boolean) => void;
	taxonomies?: TaxonomyVM[];
	topAttributes?: FilterVM[];
	userState?: UserState;
}
const HomePage: FC<HomePageProps> = ({
	addFilter,
	hasMappedAllFilters,
	registerUserClickEvent,
	setIsOnHomePage,
	taxonomies,
	topAttributes = [],
	userState
}) => {
	const navigate = useNavigate();
	const privateLabelId = 'private-label';
	const browseByCategories = [
		...(taxonomies || []),
		{ name: 'All Categories', id: 'all-categories' }
	].sort((a, b) => a.name!.localeCompare(b.name!));
	const sliderImages = ['general', 'private-label', 'newtopia'];
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		setIsOnHomePage(true);
		return () => {
			setIsOnHomePage(false);
		};
	}, [setIsOnHomePage]);
	useEffect(() => {
		let searchTrackingObject = getUrlTrackingParamsObject(
			TrackingEmailVariables.UTM_TRACKING,
			searchParams
		);
		if (searchTrackingObject.trackingParam && searchTrackingObject.trackingParam === 'true') {
			const trackingEvent: ITrackingEvent = {
				transactionId: uuidv4(),
				status: TrackingEventStatus.SUCCESS,
				type: TrackingEventType.EMAIL_TRACKING_LANDING,
				timeStamp: new Date().toISOString(),
				entityId: getLocalStorageValue(IirisIdentityKey.USER_ID) || '',
				eventPayload: {
					id: `email-tracking-landing-${new Date().toISOString()}`,
					name: 'email-tracking-landing',
					type: 'email-tracking',
					url: window.location.href,
					tags: [],
					metadata: {
						user_id: userState?.user?.user_id,
						user_type: userState?.user?.user_type,
						enviroment: process.env.REACT_APP_ENV_NAME
					}
				}
			};
			handleTracking(trackingEvent);
			delete searchTrackingObject.object[TrackingEmailVariables.UTM_TRACKING];
			setSearchParams(searchTrackingObject.object);
		}
	}, []);

	const handleNavigateToOnboard = () =>
		navigate(!isCurrentUserAnonymous() ? '/profile#5' : `../${eventPublicPathName}/getonbeacon`);
	return (
		<>
			{hasMappedAllFilters && (
				<div className='home-page-container'>
					<div className='event-slider-container'>
						<EventSliderComponent
							images={sliderImages}
							navigate={navigate}
							privateLabelFilter={topAttributes?.find(f => f.id === privateLabelId)}
							addFilter={addFilter}
							userState={userState}
						/>
					</div>
					<HomePageOnboardComponent handelNavigate={handleNavigateToOnboard} />
					<div className='what-is-new-section-container'>
						<WhatIsNewSectionComponent
							navigate={navigate}
							addFilter={addFilter}
							registerUserClickEvent={registerUserClickEvent}
							userState={userState}
						/>
					</div>
					<div className='browse-by-category-container'>
						<BrowseByCategoryComponent
							taxonomies={browseByCategories}
							navigate={navigate}
							addFilter={addFilter}
							registerUserClickEvent={registerUserClickEvent}
							userState={userState}
						/>
					</div>
					<div className='highlighted-products-container'>
						<HighlightedProducts />
					</div>
					<div className='highlighted-brands-container'>
						<HighlightedBrands />
					</div>
				</div>
			)}
		</>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	hasMappedAllFilters: state.filterState?.hasMappedAllFilters,
	taxonomies: state.storeState?.filters?.taxonomyFilters,
	topAttributes: state.filterState?.allFilters?.find(f => f.type === FilterType.ATTRIBUTES)
		?.filters,
	userState: state.userState
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	addFilter: (filter: FilterVM) => dispatch(FilterActions.addFilter(filter)),
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	},
	setIsOnHomePage: (isOnHomePage: boolean) => dispatch(AppActions.setIsOnHomePage(isOnHomePage))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
