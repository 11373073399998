import { OnboardCardComponent, underLinePosition } from '../OnboardCard/OnboardCardComponent';
import { ITimeLineStepComponentProps } from '../TimeLine/TimeLineComponent';
import { FirstTimeExpoModel as model } from './model';
import spins from '../../../assets/images/brand-onboard/time-line/spins.svg';
import upload from '../../../assets/images/brand-onboard/time-line/upload.svg';
import favorites from '../../../assets/images/brand-onboard/time-line/favorites.svg';

export const FirstTimeMobileSteps: ITimeLineStepComponentProps[] = [
	{
		leftContent: <></>,
		stepIcon: <span className='number'>1</span>,
		rightContent: (
			<>
				<OnboardCardComponent
					header={
						<div className='first-card-header-container'>
							<span className='card-text-header'>{model.firstCardPrevText}</span>{' '}
							<a
								className='card-link-header'
								href={model.firstCardLink}
								target='_blank'
								rel='noopener noreferrer'
							>
								{model.firstCardLinkText}
							</a>{' '}
							<span className='card-text-header'>{model.firstCardPostText}</span>
						</div>
					}
					content={
						<div className='first-card-content-container'>
							<span className='card-text-content'>{model.firstCardContentText}</span>
						</div>
					}
					title={model.firstCardTitle}
					titleClassName='left-card-title'
					underlinePosition={underLinePosition.BOTTOM_LEFT}
					className='mobile-negative-margin-wrapper'
				/>
				<OnboardCardComponent
					title={model.secondCardTitle}
					titleClassName='right-card-title'
					header={
						<div className='second-card-header-container'>
							<span className='card-text-header'>{model.secondCardPrevText}</span>{' '}
							<a
								className='card-link-header'
								href={model.secondCardLink}
								target='_blank'
								rel='noopener noreferrer'
							>
								{model.secondLinkText}
							</a>{' '}
						</div>
					}
					content={
						<div className='second-card-content-container'>
							<span className='card-text-content'>{model.secondCardContentText}</span>{' '}
							<a
								className='card-email-content'
								href={`mailto:${model.secondCardEmail}`}
								target='_blank'
								rel='noopener noreferrer'
							>
								{model.secondCardEmail}
							</a>
						</div>
					}
					underlinePosition={underLinePosition.BOTTOM_LEFT}
				/>
			</>
		)
	},
	{
		leftContent: <></>,
		stepIcon: <span className='number'>2</span>,
		rightContent: (
			<OnboardCardComponent
				header={
					<div className='third-card-header-container'>
						<span className='card-text-header'>{model.thirdCardPrevText}</span>{' '}
						<a
							className='card-link-header'
							href={model.thirdCardLink}
							target='_blank'
							rel='noopener noreferrer'
						>
							{model.thirdCardLinkText}
						</a>
					</div>
				}
				content={
					<div className='third-card-content-container'>
						<span className='card-text-content'>{model.thirdCardContentText}</span>
					</div>
				}
				underlinePosition={underLinePosition.BOTTOM_LEFT}
				image={<img src={spins} className='spins-img' alt='spins'></img>}
				className='mobile-spins--negative-margin-wrapper'
			/>
		)
	},
	{
		leftContent: <></>,
		stepIcon: <span className='number'>3</span>,
		rightContent: (
			<OnboardCardComponent
				image={<img src={upload} className='upload-img' alt='upload'></img>}
				header={
					<div className='fourth-card-header-container'>
						<span className='card-text-header'>{model.fourthCardPrevText}</span>{' '}
						<a
							className='card-link-header'
							href={model.fourthCardLink}
							target='_blank'
							rel='noopener noreferrer'
						>
							{model.fourthCardLinkText}
						</a>
					</div>
				}
				content={
					<div className='fourth-card-content-container'>
						<span className='bold-card-text-content'>{model.fourthCardContentBoldText}</span> {''}
						<span className='card-text-content'>{model.fourthCardContentPrev}</span>{' '}
						<a
							className='card-email-content'
							href={`mailto:${model.fourthCardEmail}`}
							target='_blank'
							rel='noopener noreferrer'
						>
							{model.fourthCardEmail}
						</a>{' '}
						<span className='card-text-content'>{model.fourthCardContentPostText}</span>
					</div>
				}
				underlinePosition={underLinePosition.BOTTOM_LEFT}
				className='negative-margin-wrapper'
			/>
		)
	},
	{
		leftContent: <></>,
		stepIcon: <i className='ri-checkbox-circle-line circle-check'></i>,
		rightContent: (
			<OnboardCardComponent
				header={
					<div className='five-card-header-container'>
						<span className='card-text-header card-mt-2'>{model.fiveCardHeaderText}</span>
					</div>
				}
				content={
					<div className='five-card-content-container'>
						<span className='card-text-content'>{model.fiveCardContentText}</span>
					</div>
				}
				image={<img src={favorites} className='favorites-img' alt='favorites'></img>}
				underlinePosition={underLinePosition.BOTTOM_LEFT}
				className='negative-margin-wrapper'
			/>
		)
	}
];
