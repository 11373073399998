import './App.scss';
import { FC } from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { AppState } from './redux/AppReducer';
import AppRoutes from './routes/AppRoutes';
import BackToTop from './components/BackToTop/BackToTopComponent';
import { useLoadTrackingScript } from './hooks/useLoadTrackingScript';
import { useWidgetLoader } from './hooks/useWidgetLoader';
import ToastContainerComponent from './components/UI/ToastComponent/ToastContainerComponent/ToastContainerComponent';
import useScrollRestoration from './hooks/useScrollRestoration';
import WithHasDataComponent from './components/WithHasDataComponent/WithHasDataComponent';
import { useUrlValidationAnonymous } from './hooks/useUrlValidationAnonymous';
import { AuthActions } from './redux/AuthActions';
import { useTokenRefresher } from './hooks/useTokenRefresher';
import { useAuthRedirection } from './hooks/useAuthRedirection';
export const App: FC<any> = ({ appState, setIsRefreshingToken }) => {
	const { userState } = appState;
	useUrlValidationAnonymous(userState);
	useWidgetLoader();
	useLoadTrackingScript();
	useScrollRestoration();
	useTokenRefresher(setIsRefreshingToken);
	useAuthRedirection();

	return (
		<div data-testid='app'>
			<ToastContainerComponent />
			<BackToTop />
			<WithHasDataComponent WrappedComponent={AppRoutes} />
		</div>
	);
};

const mapStateToProps = (state: AppState) => ({ ...state });

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setIsRefreshingToken: (isRefreshing: boolean) =>
		dispatch(AuthActions.isRefreshingToken(isRefreshing))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
